import ApiService from '@/common/api.service'
import {
  CREATE_CALIFICACION,
  READ_CALIFICACION,
  UPDATE_CALIFICACION,
  LIST_CALIFICACION,
  CREATE_ESCALA_VALORES,
  READ_ESCALA_VALORES,
  UPDATE_ESCALA_VALORES,
  LIST_ESCALA_VALORES,
} from './actions.type'
import {
  SET_CALIFICACION,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_calificaciones: {
    btn_guardar: 'false',
    list_evaluaciones: [],
    isEditing: false,
    group_escala_valores: {          
      json_group_escala_valores: {
        json_group_escala_valores: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_group_escala_valores',
          label: 'Código de escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de la escala',
          tooltip: 'Código de la escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_escala_valores: {
        co_escala_valores: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'co_escala_valores',
          label: 'Código de escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de la escala',
          tooltip: 'Código de la escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'Código de escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de la escala',
          tooltip: 'Código de la escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_denominacion_escala_valores: {
        tx_denominacion_escala_valores: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_denominacion_escala_valores',
          label: 'Denominación escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca la denominación escala de valores',
          tooltip: 'Denominación escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_valor_minimo_escala: {
        nu_valor_minimo_escala: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'nu_valor_minimo_escala',
          label: 'Valor mínimo de escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el valor mínimo escala de valores',
          tooltip: 'Valor mínimo de la escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_valor_maximo_escala: {
        nu_valor_maximo_escala: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'nu_valor_maximo_escala',
          label: 'Valor máximo de escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el valor máximo escala de valores',
          tooltip: 'Valor máximo de la escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_estatus: {
        co_estatus: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'co_estatus',
          label: 'Estatus de escala de valores *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el estatus de escala de valores',
          tooltip: 'Estatus escala de valores',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  evaluacion: null,

}

const getters = {
  
  fields_calificaciones (state) {
    return state.fields_calificaciones
  },
  evaluacion (state) {
    return state.evaluacion
  },
  list_evaluaciones (state) {
    return state.list_evaluaciones
  }

}
const actions = {
  [LIST_CALIFICACION] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_calificaciones
    return new Promise((resolve, reject) => {
      ApiService.get('WSIndicadorLogro', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_CALIFICACION] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSIndicadorLogro', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_CALIFICACION] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSIndicadorLogro/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_CALIFICACION] (context, datos) {
    console.log('Registrar UPDATE_CALIFICACION: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSIndicadorLogro/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },    
  [LIST_ESCALA_VALORES] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_calificaciones
    return new Promise((resolve, reject) => {
      ApiService.get('WSEscalaValores', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_ESCALA_VALORES] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEscalaValores', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_ESCALA_VALORES] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSEscalaValores/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_ESCALA_VALORES] (context, datos) {
    console.log('Registrar UPDATE_CALIFICACION: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSIndicadorLogro/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
}

const mutations = {
  [SET_CALIFICACION] (state, evaluacion) {
    state.evaluacion = evaluacion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
