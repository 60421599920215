import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'

// import auth from '@/store/auth.module'

import {
  API_URL_8082,
} from '@/common/config'
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = API_URL_8082

const ApiServiceImagenes = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL_8082
  },

  setHeader () {
    Vue.axios.defaults.withCredentials = true
  },

  get (resource, params)  {
    params.ticket = `${JwtService.getToken()}`
    params.username = window.localStorage.getItem("user")

    //    const headers = { "Content-Type": "application/x-www-form-urlencoded" }
    return Vue.axios.get(`${resource}`, { params } ).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
    
  },
  post (resource, params, onUploadProgress) {
    console.log('Post ', resource, params)

    params.ticket = `${JwtService.getToken()}`
    params.username = window.localStorage.getItem("user")


    //return Vue.axios.post(`${resource}`, params, options )
    return Vue.axios.post(`${resource}`, params, {
      params: params,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: onUploadProgress
    });
  },

  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put (resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete (resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiServiceImagenes