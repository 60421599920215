import ApiService from '@/common/api.service'
import {
    CREATE_ACADEMICO_WIZARD,
    CREATE_UNIDAD,
    ASOCIAR_UNIDAD_LAPSO,
    LIST_ANOS,
    LIST_ANO_NIVELES,    
    LIST_NIVELES,
    LIST_PLANES,
    LIST_GRADOS,
    LIST_PLAN_AREAS,
    LIST_AREA_FORMACION,
    LIST_PLAN_GRADOS,
    LIST_OFERTA_ACADEMICA,
    LIST_CONTENIDO,
    LIST_UNIDAD
} from './actions.type'
import {
  SET_ERROR
} from './mutations.type'


const state = {
  fields_academico: {
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },
    btn_guardar: 'false',
    isEditing: false,   
    group_ano_escolar: {
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        insert: 'SI',
        caracteristicas: {
          nombre: 'nu_id_ano_escolar',
          label: 'Id año escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el año escolar',
          tooltip: 'Año escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_pais: {
        nu_id_pais: [],
        caracteristicas: {
          nombre: 'nu_id_pais',
          label: 'País *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el país',
          tooltip: 'País',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_ano: {
        tx_nombre_ano: '',
        insert: 'SI',
        caracteristicas: {
          nombre: 'tx_nombre_ano',
          label: 'Año escolar *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del año escolar',
          tooltip: 'Nombre del año escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      fe_inicial: {
        fe_inicial: '',
        insert: 'SI',
        caracteristicas: {
          nombre: 'fe_inicial',
          label: 'Fecha inicial *',
          tipo: 'DATE',
          tamano: 'text-sm',
          placeholder: 'Introduzca la fecha inicial',
          tooltip: 'Fecha inicial',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      fe_final: {
        fe_final: '',
        insert: 'SI',
        caracteristicas: {
          nombre: 'fe_final',
          label: 'Fecha final *',
          tipo: 'DATE',
          tamano: 'text-sm',
          placeholder: 'Introduzca la fecha final',
          tooltip: 'Fecha final',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_estatus: {
        co_estatus: '',
        insert: 'SI',
        caracteristicas: {
          nombre: 'co_estatus',
          label: 'Estatus *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Seleccione el estatus del año',
          tooltip: 'Estatus',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_niveles: {
      json_group_niveles: {
        json_group_niveles: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_group_niveles',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nivel: {
        tx_nivel: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_nivel',
          label: 'Nivel educativo *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del nivel educativo',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_etiqueta: {
        tx_etiqueta: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_etiqueta',
          label: 'Etiqueta del nivel educativo *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca la etiqueta del nivel educativo',
          tooltip: 'Etiqueta del Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_descripcion: {
        tx_descripcion: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_descripcion',
          label: 'Descripción del nivel educativo *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca la descripción del nivel educativo',
          tooltip: 'Descripción del Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel_padre: {
        json_nivel_padre: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_nivel_padre',
          label: 'Nivel educativo padre *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nivel educativo padre',
          tooltip: 'Descripción del nivel educativo padre',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_plan_estudio: {
      json_group_plan_estudio: {
        json_group_plan_estudio: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_group_plan_estudio',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel: {
        json_nivel: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_nivel',
          label: 'Nivel educativo *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del nivel educativo',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_plan_estudio: {
        co_plan_estudio: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'co_plan_estudio',
          label: 'Código del plan de estudio *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el plan de estudio',
          tooltip: 'Plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_descripcion: {
        tx_descripcion: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_descripcion',
          label: 'Descripción del plan de estudio *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca la descripción del plan de estudio',
          tooltip: 'Descripción del plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },    
    group_grado: {
      json_group_grado: {
        json_group_grado: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_group_grado',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel: {
        json_nivel: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_nivel',
          label: 'Nivel educativo *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_ano: {
        tx_nombre_ano: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_nombre_ano',
          label: 'Descripción del contenido*',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca la descripción del contenido',
          tooltip: 'Descripción del contenido',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_plan_estudio: {
        co_plan_estudio: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'co_plan_estudio',
          label: 'Plan de estudio*',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el plan de estudio',
          tooltip: 'Descripción del plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_grado: {
        tx_nombre_grado: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_nombre_grado',
          label: 'Nivel / Grado / Año *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca Nivel / Grado / Año',
          tooltip: 'Descripción del Nivel / Grado / Año',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_area_formacion: {
      json_group_area_formacion: {
        json_group_area_formacion: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_group_area_formacion',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel: {
        json_nivel: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_nivel',
          label: 'Nivel educativo *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },      
      json_grados: {
        json_grados: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_grados',
          label: 'Nivel/Grado/año *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Nivel/Grado/año',
          tooltip: 'Nivel/Grado/año',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_plan_estudio: {
        co_plan_estudio: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'co_plan_estudio',
          label: 'Código del plan de estudio *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el plan de estudio',
          tooltip: 'Plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_area: {
        json_area: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_area',
          label: 'Áreas de Formación *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Marque las área de Formación',
          tooltip: 'Áreas de Formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_contenido: {
      json_group_contenido: {
        json_group_contenido: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_group_contenido',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_area: {
        json_area: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_area',
          label: 'Áreas de Formación *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Marque las área de Formación',
          tooltip: 'Áreas de Formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel: {
        json_nivel: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_nivel',
          label: 'Nivel educativo *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },      
      json_grados: {
        json_grados: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_grados',
          label: 'Nivel/Grado/año *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Nivel/Grado/año',
          tooltip: 'Nivel/Grado/año',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_plan_estudio: {
        co_plan_estudio: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'co_plan_estudio',
          label: 'Código del plan de estudio *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el plan de estudio',
          tooltip: 'Plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_contenido: {
        tx_contenido: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'tx_contenido',
          label: 'Descripción del contenido*',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca la descripción del contenido',
          tooltip: 'Descripción del contenido',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_unidad: {
      json_group_unidad: {
        json_group_unidad: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_group_unidad',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_lapso',
          label: 'nu_id_ano_escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'nu_id_institucion *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_nivel_educativo: {
        nu_id_nivel_educativo: '',
        caracteristicas: {
          nombre: 'nu_id_nivel_educativo',
          label: 'nu_id_ano_escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_grado: {
        nu_id_grado: '',
        caracteristicas: {
          nombre: 'nu_id_grado',
          label: 'nu_id_grado *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_area_formacion: {
        nu_id_area_formacion: '',
        caracteristicas: {
          nombre: 'nu_id_area_formacion',
          label: 'nu_id_grado *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_lapso: {
        json_lapso: '',
        caracteristicas: {
          nombre: 'json_lapso',
          label: 'Momento/Lapso/Periodo *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'Momento/Lapso/Periodo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_unidad: {
        co_unidad: '',
        caracteristicas: {
          nombre: 'co_unidad',
          label: 'Código de la unidad *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código unidad',
          tooltip: 'Código de la unidad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_denominacion_unidad: {
        tx_denominacion_unidad: '',
        caracteristicas: {
          nombre: 'tx_denominacion_unidad',
          label: 'Unidad *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Introduzca la unidad',
          tooltip: 'Unidad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_unidades: {
        json_unidades: '',
        caracteristicas: {
          nombre: 'json_unidades',
          label: 'Unidad *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la unidad',
          tooltip: 'Unidad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
            
    },
    group_actividad: {
      json_group_actividad: {
        json_group_actividad: [],
        insert: 'SI',
        caracteristicas: {
          nombre: 'json_group_actividad',
          label: 'Id Nivel *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_lapso',
          label: 'nu_id_ano_escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'nu_id_institucion *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_nivel_educativo: {
        nu_id_nivel_educativo: '',
        caracteristicas: {
          nombre: 'nu_id_nivel_educativo',
          label: 'nu_id_ano_escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_grado: {
        nu_id_grado: '',
        caracteristicas: {
          nombre: 'nu_id_grado',
          label: 'nu_id_grado *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_area_formacion: {
        nu_id_area_formacion: '',
        caracteristicas: {
          nombre: 'nu_id_area_formacion',
          label: 'nu_id_grado *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_lapso: {
        json_lapso: '',
        caracteristicas: {
          nombre: 'json_lapso',
          label: 'Momento/Lapso/Periodo *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'Momento/Lapso/Periodo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_actividad: {
        co_actividad: '',
        caracteristicas: {
          nombre: 'co_actividad',
          label: 'Código de la actividad *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de la actividad',
          tooltip: 'Código de la actividad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_denominacion_actividad: {
        tx_denominacion_actividad: '',
        caracteristicas: {
          nombre: 'tx_denominacion_actividad',
          label: 'Actividad *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de la actividad',
          tooltip: 'Actividad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },      
      nu_ponderacion_actividad: {
        nu_ponderacion_actividad: '',
        caracteristicas: {
          nombre: 'nu_ponderacion_actividad',
          label: 'Ponderación *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Introduzca la ponderación',
          tooltip: 'Ponderación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_actividades: {
        json_acrividades: '',
        caracteristicas: {
          nombre: 'json_acrividades',
          label: 'Actividad *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la actividad',
          tooltip: 'Actividad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
            
    },    

  }  
}

const getters = {
  fields_academico (state) {
    return state.fields_academico
  },  

}
const actions = {
  [CREATE_ACADEMICO_WIZARD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSWizardAcademico/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [CREATE_UNIDAD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSUnidad/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_ANOS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSAnoEscolar', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_ANO_NIVELES] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSAnoNiveles', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_NIVELES] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSNivel', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_PLANES] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPlanEstudio', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_GRADOS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSGrados', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_PLAN_GRADOS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPlanGrados', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_PLAN_AREAS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPlanArea', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_AREA_FORMACION] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSAreaFormacion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_OFERTA_ACADEMICA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSOfertaAcademica', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_CONTENIDO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSContenido', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [ASOCIAR_UNIDAD_LAPSO] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSAsociarUnidadLapso/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_UNIDAD] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSUnidad', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },

  
}

const mutations = {

}

export default {
  state,
  actions,
  mutations,
  getters
}
