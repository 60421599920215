// Habilitar Produccion -Pruebas
//export const API_URL_8082 = 'https://66.97.34.241:8084/api_agenda'
//export const API_URL = 'https://66.97.34.241:8084/api_agenda/'
//export const API_URL_IMG = 'https://66.97.34.241:8083/images/'
// Habilitar Produccion
export const API_URL_8082 = 'https://norwservice.com:8081/api'
export const API_URL = 'https://norwservice.com:8081/api/'
export const API_URL_IMG = 'https://norwservice.com/images/'
// Fin Habilitar Produccion
// Habilitar Para Desarrollo Con Apache
// export const API_URL = 'http://localhost:8082'
// export const API_URL_IMG = 'http://localhost:8082/static/archivos/images/'
// export const API_URL_8082 = 'http://localhost:8082'
// Fin Habilitar Para Desarrollo Con Apache
export const API_URL_8086 = 'http://localhost:8086'
export const API_URL_8087 = 'http://localhost:8087' /* App Proveedor */
export const API_URL_8088 = 'http://localhost:8088' /* App Ubigeo */
export const SERVICIO_CAS = 'http://localhost:8082/' /* vue */
export const VALIDADOR_CAS = '/cas/p3/serviceValidate'
export const SOLICITAR_TICKET = '/cas/v1/tickets'
export const DEFAULT_AUTEN = 'CAS'
export default API_URL