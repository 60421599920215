import ApiService from '@/common/api.service'
import {
  READ_TRATAMIENTO,
  CREATE_TRATAMIENTO,
  UPDATE_TRATAMIENTO,
  READ_TRATAMIENTO_AGENDA
} from './actions.type'
import {
  SET_TRATAMIENTO,
  SET_ERROR
} from './mutations.type'

const state = {
  tratamiento: null,
  tratamiento_agenda: null,
  fields_tratamiento: {
    json_medicamento: {
      json_medicamento: '',
      caracteristicas: {
        nombre: 'json_medicamento',
        label: 'Medicamento *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione un medicamento',
        tooltip: 'Seleccione un medicamento',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    tx_dosis: {
      tx_dosis: '',
      caracteristicas: {
        nombre: 'tx_dosis',
        label: 'Dosis *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese la dosis a suministrar',
        tooltip: 'Ingrese la dosis a suministrar',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    fe_inicio: {
      fe_inicio: '',
      caracteristicas: {
        nombre: 'fe_inicio',
        label: 'Fecha de inicio del tratamiento *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese la Fecha de inicio del tratamiento',
        tooltip: 'Ingrese la Fecha de inicio del tratamiento',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    hh_inicio: {
      hh_inicio: '',
      caracteristicas: {
        nombre: 'hh_inicio',
        label: 'Hora de inicio del tratamiento *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese la Hora de inicio del tratamiento',
        tooltip: 'Ingrese la Hora de inicio del tratamiento',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    json_frecuencia_medida: {
      json_frecuencia_medida: '',
      caracteristicas: {
        nombre: 'json_frecuencia_medida',
        label: 'Frecuencia de administración del medicamento *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Frecuencia de administración del medicamento',
        tooltip: 'Seleccione la Frecuencia de administración del medicamento',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    nu_frecuencia_cantidad: {
      nu_frecuencia_cantidad: '',
      caracteristicas: {
        nombre: 'nu_frecuencia_cantidad',
        label: 'Frecuencia de administración del medicamento *',
        tipo: 'TEXT',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Frecuencia de administración del medicamento',
        tooltip: 'Seleccione la Frecuencia de administración del medicamento',
        tooltip_pos:'bottom',
        required: 'required|max:70',        
        default: ''
      }
    },    
    json_duracion_medida: {
      json_duracion_medida: '',
      caracteristicas: {
        nombre: 'json_duracion_medida',
        label: 'Periodo de duración del tratamiento *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione el Periodo de duración del tratamiento',
        tooltip: 'Seleccione el Periodo de duración del tratamiento',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    nu_duracion_cantidad: {
      nu_duracion_cantidad: '',
      caracteristicas: {
        nombre: 'nu_duracion_cantidad',
        label: 'Periodo de duración del tratamiento *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione el Periodo de duración del tratamiento',
        tooltip: 'Seleccione el Periodo de duración del tratamiento',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    bo_seguimiento: {
      bo_seguimiento: '',
      caracteristicas: {
        nombre: 'bo_seguimiento',
        label: 'Desea hacerle seguimiento a la frecuencia de administración? *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },        
    nu_id_frecuencia_medida: {
      nu_id_frecuencia_medida: '',
      caracteristicas: {
        nombre: 'nu_id_frecuencia_medida',
        label: 'Desea hacerle seguimiento a la frecuencia de administración? *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    nu_id_consulta: {
      nu_id_consulta: '',
      caracteristicas: {
        nombre: 'nu_id_consulta',
        label: 'Desea hacerle seguimiento a la frecuencia de administración? *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    nu_id_paciente: {
      nu_id_paciente: '',
      caracteristicas: {
        nombre: 'nu_id_paciente',
        label: 'Desea hacerle seguimiento a la frecuencia de administración? *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    nu_id_tratamiento: {
      nu_id_tratamiento: '',
      caracteristicas: {
        nombre: 'nu_id_tratamiento',
        label: 'Desea hacerle seguimiento a la frecuencia de administración? *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip: 'Seleccione si Desea hacerle seguimiento a la frecuencia de administración?',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },

    btn_guardar: 'false',
    isEditing: false    

  }

}

const getters = {
  fields_tratamiento (state) {
    return state.fields_tratamiento
  },
  tratamiento (state) {
    return state.tratamiento
  },
  tratamiento_agenda (state) {
    return state.tratamiento_agenda
  }

}
const actions = {
  [READ_TRATAMIENTO_AGENDA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSTratamientoAgenda', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_TRATAMIENTO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSTratamiento', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_TRATAMIENTO] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSTratamiento/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_TRATAMIENTO] (context, datos) {
    console.log('Registrar UPDATE_TRATAMIENTO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedica/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_TRATAMIENTO] (state, tratamiento) {
    state.tratamiento = tratamiento
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
