import ApiService from '@/common/api.service'
import {
  LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREA,
  CREATE_ASOCIAR_INSTITUCION  
} from './actions.type'
import {
  SET_ASOCIACIONES,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_asociaciones: {
    btn_guardar: 'false',
    list_instituciones: [],
    isEditing: false,
    group_ano_institucion: {    
      json_ano_institucion: {
        json_ano_institucion: [],
        caracteristicas: {
          nombre: 'json_ano_institucion',
          label: 'Institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_ano_escolar',
          label: 'Año escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_ano: {
        tx_nombre_ano: '',
        caracteristicas: {
          nombre: 'tx_nombre_ano',
          label: 'Año escolar *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el año escolar',
          tooltip: 'Año escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_institucion: {
        json_institucion: '',
        caracteristicas: {
          nombre: 'json_institucion',
          label: 'Institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel: {
        json_nivel: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_nivel',
          label: 'Nivel educativo *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del nivel educativo',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_plan_estudio: {
        json_plan_estudio: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_plan_estudio',
          label: 'Código del plan de estudio *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el plan de estudio',
          tooltip: 'Plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_grados: {
        json_grados: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_grados',
          label: 'Nivel/Grado/Año *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Nivel/Grado/Año',
          tooltip: 'Nivel/Grado/Año',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_areas: {
        json_areas: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_areas',
          label: 'Área de formación *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el área de formación',
          tooltip: 'Área de formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  institucion: null,

}

const getters = {
  fields_asociaciones (state) {
    return state.fields_asociaciones
  },
}
const actions = {
  [CREATE_ASOCIAR_INSTITUCION] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSAsociarInstitucion/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREA] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_asociaciones
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucionNivelPlanGradoAreas', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_ASOCIACIONES] (state, institucion) {
    state.institucion = institucion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
