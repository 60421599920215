import { 
  SET_MODAL_CLIENTE, 
  SET_MODAL_CONTACTO, 
  SET_MODAL_AGENDA,
  SET_MODAL_USUARIO, 
  SET_MODAL_PERSONA, 
  SET_MODAL_INMOBILIARIA, 
  SET_MODAL_CITA,
  SET_OPERACION ,
  SET_OPERACION_AGENDA,
  SET_MODAL_USUARIO_REGISTRAR
} from './mutations.type'
//import { REG_MODAL } from '@/store/actions.type'

export const state = {
  statusModalCliente: false,
  statusModalContacto: false,
  statusModalAgenda: false,
  statusModalUsuario: false,
  statusModalPersona: false,  
  statusModalCita: false,  
  statusModalUsuarioRegistrar :false,
  operacion: {
    tipo:'',
    id: '',
    nombres:'',
    apellidos:'',
    direccion: '',
  },
  operacion_agenda: {
    tipo:'',
    fecha: '',
  },

}
const getters = {

  statusModalCliente (state) {
    return state.statusModalCliente
  },
  statusModalContacto (state) {
    return state.statusModalContacto
  },
  statusModalAgenda (state) {
    return state.statusModalAgenda
  },
  statusModalUsuario (state) {
    return state.statusModalUsuario
  },
  statusModalPersona (state) {
    return state.statusModalPersona
  },
  statusModalCita (state) {
    return state.statusModalCita
  },
  statusModalInmobiliaria (state) {
    return state.statusModalInmobiliaria
  },
  operacion (state) {
    return state.operacion
  },
  operacion_agenda (state) {
    return state.operacion_agenda
  },
  statusModalUsuarioRegistrar (state) {
    return state.statusModalUsuarioRegistrar
  },

}
const actions = {
  //[REG_MODAL] (context, datos) {
  //  context.commit(SET_MODAL, datos)
    // ApiService.setHeader()
  //}
}
/* eslint no-param-reassign: ['error', { 'props': false }] */
export const mutations = {
  [SET_MODAL_CLIENTE] (state, params) {
    console.log('SET MODAL cliente params: ', params)
    state.statusModalCliente = params
  },
  [SET_MODAL_CONTACTO] (state, params) {
    console.log('SET MODAL contacto params: ', params)
    state.statusModalContacto = params
  },
  [SET_MODAL_AGENDA] (state, params) {
    console.log('SET MODAL contacto params: ', params)
    state.statusModalAgenda = params
  },
  [SET_MODAL_USUARIO] (state, params) {
    console.log('SET MODAL contacto params: ', params)
    state.statusModalUsuario = params
  },
  [SET_MODAL_PERSONA] (state, params) {
    console.log('SET MODAL PERSONA params: ', params)
    state.statusModalPersona = params
  },
  [SET_MODAL_CITA] (state, params) {
    console.log('SET MODAL CITA params: ', params)
    state.statusModalCita = params
  },
  [SET_OPERACION] (state, params) {
    console.log('SET OPERACION params: ', params)
    state.operacion = params
  },
  [SET_OPERACION_AGENDA] (state, params) {
    console.log('SET OPERACION params: ', params)
    state.operacion_agenda = params
  },
  [SET_MODAL_INMOBILIARIA] (state, params) {
    console.log('SET_MODAL_INMUEBLE params: ', params)
    state.statusModalInmobiliaria = params
  },
  [SET_MODAL_USUARIO_REGISTRAR] (state, params) {
    console.log('SET MODAL USUARIO params: ', params)
    state.statusModalUsuarioRegistrar = params
  },  
  

}

export default {
  state,
  getters,
  actions,
  mutations
}
