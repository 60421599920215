import ApiService from '@/common/api.service'
import {
  REG_VENTA,
  MUESTRA_VENTA
} from '@/store/actions.type'

import {
  SET_ERROR,
  SET_VENTA  
} from '@/store/mutations.type'

const state = {
  ventas: [],
}


const getters = {
  ventas (state) {
    return state.ventas
  }

}
const actions = {
  [MUESTRA_VENTA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSVenta', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },  
  [REG_VENTA] (context, datos) {
    console.log('Registrar REG_VENTA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSVenta/registrar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_VENTA] (state, valor) {
    state.ventas = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
