import { ApiLogin } from '@/common/api.service'


import {
  LOGIN,
  LOGOUT,
  USUARIO_VALIDA
} from '@/store/actions.type'

import {
  SET_ERROR,
  SET_USER,
  SET_MENSAJE,
  SET_LOGOUT,
  SET_isAuthenticated,
  SET_PERMISO
} from '@/store/mutations.type'

const state = {
  errores: null,
  permiso: null,
  isAuthenticated: false,
  item: '',
  mensaje: {
            group: null,
            title: null,
            text: null
      },
  user: {
    username: '',
    token: '',
    perfiles: []
  }
}

const getters = {
  isAuthenticated () {
    return this.$vueAuth.isAuthenticated()
  },  
  currentUser (state) {
    return state.user
  },
  filteredPermisos (state) {
    var index = this.currentUser.autorizacion.findIndex(i => i === state.permiso);
    console.log('param: ', index, state.permiso)
    if (index >= 0) {
      return true
    } else {
      return false
    }    
    //return state.user
  },
  errores (state) {
    return state.errores
  },
  mensaje (state) {
    return state.mensaje
  },

}


const actions = {
  [USUARIO_VALIDA] (context, datos) {
    // ApiService.setHeader()
    ApiLogin.init()
    return new Promise((resolve, reject) => {
      ApiLogin.get('WSUser/valida', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LOGOUT] (context) {
    ApiLogin.init()

    return new Promise((resolve) => {
      context.commit(SET_LOGOUT, '')
      resolve()
    })
  },  
  [LOGIN] (context, datos) {
    //ApiLogin.setHeader()
    ApiLogin.init()

    return new Promise((resolve, reject) => {
      ApiLogin.get('WSUser/login', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, 'Error al Autenticarse')
          reject(response)
        })
    })
  }
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_LOGOUT] (state, user) {
    localStorage.removeItem('user-JWT__access__token') // clear your user's token from localstorage
    localStorage.clear()
    state.user = user
  },
  [SET_USER] (state, user) {
    console.log('Credenciales: ', user)
    state.user = user
  },
  [SET_ERROR] (state, error) {
    state.errores = error
  },
  [SET_MENSAJE] (state, mensaje) {
    state.mensaje = mensaje
  },
  [SET_isAuthenticated] (state, payload) {
    state.isAuthenticated = payload.isAuthenticated
  },
  [SET_PERMISO] (state, payload) {
    state.permiso = payload
  }
    
}

export default {
  state,
  getters,
  actions,
  mutations
}
