import ApiService from '@/common/api.service'

import {
  MUESTRA_CLIENTE,
  MUESTRA_CLIENTE_LISTA,
  REG_CLIENTE,
  EDIT_CLIENTE,
  MUESTRA_TIPO_CAPTACION,
  REFRESCAR_CLIENTE,
  REFRESCAR_CLIENTE_LISTA
} from './actions.type'
import {
  SET_CLIENTE,
  SET_CLIENTE_NUEVO,
  SET_ERROR,
  SET_CLIENTE_LISTA
} from './mutations.type'

const state = {
  cliente: [],
  cliente_lista: [],
  cliente_nuevo:[] ,
  errores: null
}

const getters = {
  clientes (state) {
    return state.cliente
  },
  clientes_lista (state) {
    return state.cliente_lista
  },
  cliente_nuevo (state) {
    return state.cliente_nuevo
  }


}
const actions = {
  [REFRESCAR_CLIENTE_LISTA] (context) {
    // ApiService.setHeader()
    context.dispatch(MUESTRA_CLIENTE_LISTA, {}).then(response => {
      context.commit(SET_CLIENTE_LISTA, response.rest.items)
    }, error => {
      context.commit(SET_ERROR, error.statusText)
    })        

  },
  [REFRESCAR_CLIENTE] (context) {
    // ApiService.setHeader()
    context.dispatch(MUESTRA_CLIENTE, {}).then(response => {
      context.commit(SET_CLIENTE, response.rest.items)
    }, error => {
      context.commit(SET_ERROR, error.statusText)
    })        

  },
  [MUESTRA_CLIENTE] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSCliente', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [MUESTRA_CLIENTE_LISTA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSClienteLista', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [REG_CLIENTE] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCliente/registrar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [EDIT_CLIENTE] (context, datos) {
    console.log('Registrar EDIT_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCliente/editar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },  
  [MUESTRA_TIPO_CAPTACION] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSTipoCaptacion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  
}

const mutations = {
  [SET_CLIENTE] (state, datos) {
    state.cliente = datos
  },
  [SET_CLIENTE_NUEVO] (state, datos) {
    state.cliente_nuevo = datos
  },
  [SET_CLIENTE_LISTA] (state, datos) {
    state.cliente_lista = datos
  },

    
}

export default {
  state,
  actions,
  mutations,
  getters
}
