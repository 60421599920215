import Vue from 'vue'
import Vuex from 'vuex'

import menu from './menu.module'
import persona from './persona.module'
import auth from './auth.module'
import ubigeo from './ubigeo.module'
import utilitario from './utilitario.module'
import comentario from './comentario.module'
import cita from './cita.module'
import contacto from './contacto.module'
import cliente from './cliente.module'
import usuario from './usuario.module'
import seguro from './seguro.module'
import venta from './venta.module'
import inmobiliaria from './inmobiliaria.module'
import imagenes from './imagenes.module'
import paciente from './paciente.module'
import consulta_medica from './consulta_medica.module'
import especialidad from './especialidad.module'
import centro_medico from './centro_medico.module'
import medico from './medico.module'
import especialidad_usuario from './especialidad_usuario.module'
import centro_medico_usuario from './centro_medico_usuario.module'
import tratamiento from './tratamiento.module'
import visita_medica from './visita_medica.module'
import perfil from './perfil.module'
import radiologia from './radiologia.module'
import laboratorio from './laboratorio.module'
import agenda from './agenda.module'
import historia_clinica from './historia_clinica.module'
import usuario_perfil from './usuario_perfil.module'
import paciente_representante from './paciente_representante.module'
import usuario_especialidad from './usuario_especialidad.module'
import wizard_academico from './wizard_academico.module'
import wizard_institucion from './wizard_institucion.module'
import wizard_evaluacion from './wizard_evaluacion.module'
import wizard_indicadores from './wizard_indicadores.module'
import wizard_competencias from './wizard_competencias.module'
import wizard_lapsos from './wizard_lapsos.module'
import wizard_calificaciones from './wizard_calificaciones.module'
import wizard_oferta_academica from './wizard_oferta_academica.module'

import wizard_asociaciones from './wizard_asociaciones.module'
import corporativas from './corporativas.module'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    utilitario,
    auth,
    menu,
    persona,
    ubigeo,
    comentario,
    cita,
    contacto,
    cliente,
    seguro,
    usuario,
    venta,
    imagenes,
    inmobiliaria,
    paciente,
    consulta_medica,
    especialidad,
    centro_medico,
    medico,
    especialidad_usuario,
    centro_medico_usuario,
    tratamiento,
    visita_medica,
    perfil,
    laboratorio,
    radiologia,
    agenda,
    historia_clinica,
    usuario_perfil,
    paciente_representante,
    usuario_especialidad,
    wizard_academico,
    wizard_institucion,
    wizard_asociaciones,
    corporativas,
    wizard_evaluacion,
    wizard_indicadores,
    wizard_competencias,
    wizard_lapsos,
    wizard_calificaciones,
    wizard_oferta_academica

  }
})
