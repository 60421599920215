import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { ValidationProvider, ValidationObserver} from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);
import Notifications from "vt-notifications";
Vue.use(Notifications);
import './assets/css/tailwind.css'
import './assets/const/validacion.js'
import 'tw-elements';
Vue.config.productionTip = false
import moment from 'moment'

Vue.prototype.moment = moment

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
