import ApiService from '@/common/api.service'
import {
  READ_ESPECIALIDAD,
  CREATE_ESPECIALIDAD,
  UPDATE_ESPECIALIDAD,
  LIST_ESPECIALIDAD
} from './actions.type'
import {
  SET_ESPECIALIDAD,
  SET_ERROR
} from './mutations.type'

const state = {
  especialidad: null,
  fields_especialidad: {
    group_datos_basico: {    
      nu_id_especialidad: {
        nu_id_especialidad: '',
        caracteristicas: {
          nombre: 'nu_id_especialidad',
          label: 'Eepecialidad *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la especialidad',
          tooltip: 'Especialidad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_especialidad: {
        tx_especialidad: '',
        caracteristicas: {
          nombre: 'tx_especialidad',
          label: 'Especialidad *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la especialidad',
          tooltip: 'Especialidad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },      
      co_especialidad: {
        co_especialidad: '',
        caracteristicas: {
          nombre: 'co_especialidad',
          label: 'Codigo de la especialidad *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de la especialidad',
          tooltip: 'Codigo de la especialidad',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },      

    }
  }
}

const getters = {
  fields_especialidad (state) {
    return state.fields_especialidad
  },
  especialidad (state) {
    return state.especialidad
  }

}
const actions = {
  [LIST_ESPECIALIDAD] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEspecialidad', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_ESPECIALIDAD] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEspecialidad', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_ESPECIALIDAD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSEspecialidad/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_ESPECIALIDAD] (context, datos) {
    console.log('Registrar UPDATE_ESPECIALIDAD: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSEspecialidad/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_ESPECIALIDAD] (state, especialidad) {
    state.especialidad = especialidad
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
