export const SET_LOGOUT = 'logOut'
export const SET_USER = 'setUser'
export const SET_ERROR = 'setError'
export const SET_isAuthenticated = 'setisAuthenticated'

// DESDE AQUI //
export const SET_PERSONA = 'setPersona'
// MENU //
export const SET_MENU = 'setMenu'
export const SET_OPEN_TAB = 'setOpenTab'
export const SET_ACTIVO_MENU = 'setActivoMenu'
// MENU //
export const SET_COD_POSTALES = 'setCodPostales'
// MODAL //
export const SET_MODAL_CLIENTE = 'setModalCliente'
export const SET_MODAL_CONTACTO = 'setModalContacto'
export const SET_MODAL_AGENDA = 'setModalAgenda'
export const SET_MODAL_USUARIO = 'setModalUsuario'
export const SET_MODAL_PERSONA = 'setModalPersona'
export const SET_MODAL_CITA = 'setModalCita'
export const SET_MODAL_INMOBILIARIA = 'setModalInmobiliaria'
export const SET_MODAL_USUARIO_REGISTRAR = 'setUsuarioRegistrar'

// MODAL //
export const SET_OPERACION = 'setOperacion'
export const SET_OPERACION_AGENDA = 'setOperacionAgenda'

// COMENTARIOS //
export const SET_COMENTARIO = 'setComentario'
// CITAS //
export const SET_CITA = 'setCita'
export const SET_CITAS = 'setCitas'
export const SET_STATUS_CITAS = 'setStatusCitas'

// CLIENTES //
export const SET_CLIENTE = 'setCliente'
export const SET_CLIENTE_NUEVO = 'setClienteNuevo'
export const SET_CLIENTE_LISTA = 'setClienteLista'


// USUARIOS //
export const SET_USUARIO = 'setUsuario'
export const SET_USUARIO_REGISTRADO = 'setUsuarioRegistrado'
export const SET_MENSAJE = 'setMensaje'
// CONTACTO //
export const SET_CONTACTO = 'setContacto'
// SEGURO //
export const SET_SEGURO = 'setSeguro'
// VENTA //
export const SET_VENTA = 'setVenta'
// INMOBILIARIA //
export const SET_INMUEBLE = 'setInmueble'

// representante //
export const SET_REPRESENTANTE = 'setRepresentante'
// paciente //
export const SET_PACIENTE = 'setPaciente'
// Consulta Medica //
export const SET_CONSULTA_MEDICA = 'setConsultaMedica'
// ESPECIALIDAD //
export const SET_ESPECIALIDAD = 'setEspecialidad'
// CENTRO MEDICO //
export const SET_CENTRO_MEDICO = 'setCentroMedico'
// MEDICO //
export const SET_MEDICO = 'setMedico'
// ESPECIALIDAD //
export const SET_ESPECIALIDAD_USUARIO = 'setEspecialidadUsuario'
// CENTRO MEDICO //
export const SET_CENTRO_MEDICO_USUARIO = 'setCentroMedicoUsuario'
// TRATAMIENTO //
export const SET_TRATAMIENTO = 'setTratamiento'
// TRATAMIENTO //
export const SET_VISITA_MEDICA = 'setVisitaMedica'
// NOTIFICACION //
export const SET_NOTIFICACION = 'setNotificacion'
export const SET_PERFIL = 'setPerfil'
// RADIOLOGIA //
export const SET_RADIOLOGIA = 'setRadiologia'
// laboratorio //
export const SET_LABORATORIO = 'setLaboratorio'
// Historia Medica //
export const SET_HISTORIA_CLINICA = 'setHistoriaMedica'
// CONFIGURACION //
export const SET_INSTITUCION = 'setInstitucion'
// CONFIGURACION //
export const SET_ASOCIACIONES = 'setAsociaciones'
// EVALUACIONES //
export const SET_EVALUACION = 'setEvaluacion'
// EVALUACIONES //
export const SET_INDICADOR = 'setIndicadorLogro'
// EVALUACIONES //
export const SET_COMPETENCIA = 'setCompetencia'
// EVALUACIONES //
export const SET_LAPSO = 'setLapso'
// EVALUACIONES //
export const SET_CALIFICACION = 'setCalificacion'
// EVALUACIONES //
export const SET_PERMISO = 'setPermiso'




