import ApiService from '@/common/api.service'
import {
  CREATE_CITA,
  EDITAR_CITA,  
  MUESTRA_CITA,
  MUESTRA_STATUS_CITA,  
  REG_RESULTADO_CITA,
  CANCELAR_CITA  
} from '@/store/actions.type'

import {
  SET_ERROR,
  SET_CITA,  
  SET_CITAS,
  SET_STATUS_CITAS  
} from '@/store/mutations.type'

const state = {
  cita: [],
  citas: [],
  status_citas: []
}


const getters = {
  cita (state) {
    return state.cita
  },
  citas (state) {
    return state.citas
  },
  status_citas (state) {
    return state.status_citas
  }

}
const actions = {
  [MUESTRA_STATUS_CITA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSStatusCita', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },  
  [MUESTRA_CITA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSCita', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },  
  [CREATE_CITA] (context, datos) {
    console.log('Registrar REG_COMENTARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCita/registrar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },    
  [REG_RESULTADO_CITA] (context, datos) {
    console.log('REG_RESULTADO_CITA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCita/resultado',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [CANCELAR_CITA] (context, datos) {
    console.log('REG_RESULTADO_CITA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCita/cancelar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [EDITAR_CITA] (context, datos) {
    console.log('Editar Cita: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCita/editar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },

}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_CITA] (state, valor) {
    state.cita = valor
  },
  [SET_CITAS] (state, valor) {
    state.citas = valor
    console.log(" Valor Citas: ", valor)
    console.log(" Citas Valor Citas: ", state.citas)
  },
  [SET_STATUS_CITAS] (state, valor) {
    state.status_citas = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
