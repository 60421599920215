import ApiService from '@/common/api.service'
import {
  MUESTRA_PERSONA,
  CREATE_PERSONA,
  LIST_PERSONA
} from './actions.type'
import {
  SET_PERSONA,
  SET_ERROR
} from './mutations.type'

const state = {
  persona: null,
  fields_persona: {
    group_datos_basico: {
        nu_id_persona: {
          nu_id_persona: '',
          caracteristicas: {
            nombre: 'nu_id_persona',
            label: 'Persona *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Seleccione la Parroquia',
            tooltip: 'Parroquia',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        tx_nombre_completo: {
          tx_nombre_completo: '',
          caracteristicas: {
            nombre: 'tx_nombre_completo',
            label: 'paciente *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Seleccione la Parroquia',
            tooltip: 'Parroquia',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }

        },
        tx_nacionalidad: {
          tx_nacionalidad: '',
          caracteristicas: {
            nombre: 'tx_nacionalidad',
            label: 'Nacionalidad *',
            tipo: 'text',
            tamano: 'text-sm',
            placeholder: 'Ingrese nacionalidad',
            tooltip: 'Nacionalidad del paciente',
            required: 'required',        
            default: ''
          }
        },
        tx_documento_identidad: {
          tx_documento_identidad: '',
          caracteristicas: {
            nombre: 'tx_documento_identidad',
            label: 'Cédula *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Ingrese cédula de identidad',
            tooltip: 'Cédula de identidad del paciente',
            tooltip_pos:'bottom',
            required: 'required|max:10|numeric',        
            default: ''
          }
        },
        fe_nacimiento: {
          fe_nacimiento: '',
          caracteristicas: {
            nombre: 'fe_nacimiento',
            label: 'Fecha de nacimiento *',
            tipo: 'date',
            tamano: 'text-sm',
            placeholder: 'Ingrese la fecha de nacimiento',
            tooltip: 'Fecha de nacimiento del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        tx_apellidos: {
          tx_apellidos: '',
          caracteristicas: {
            nombre: 'tx_apellidos',
            label: 'Apellidos *',
            tipo: 'text',
            tamano: 'text-sm',
            placeholder: 'Ingrese los apellidos del paciente',
            tooltip: 'Apellidos del paciente',
            required: 'required',        
            default: ''
          }
        },
        tx_nombres: {
          tx_nombres: '',
          caracteristicas: {
            nombre: 'tx_nombres',
            label: 'Nombres *',
            tipo: 'text',
            tamano: 'text-sm',
            placeholder: 'Ingrese los nombres del paciente',
            tooltip: 'Nombres del paciente',
            required: 'required',        
            default: ''
          }
        },
        nu_sexo: {
          nu_sexo: '',
          caracteristicas: {
            nombre: 'nu_sexo',
            label: 'Sexo *',
            tipo: 'date',
            tamano: 'text-sm',
            placeholder: 'Seleccione el sexo del paciente',
            tooltip: 'Sexo del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        nu_edad: {
          nu_edad: '',
          caracteristicas: {
            nombre: 'nu_edad',
            label: 'Edad *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Introduzca la edad del paciente',
            tooltip: 'Edad del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },  
        nu_estado_civil: {
          nu_estado_civil: '',
          caracteristicas: {
            nombre: 'nu_estado_civil',
            label: 'Estado civil *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Introduzca el estado civil del paciente',
            tooltip: 'Estado civil del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },  
        nu_nivel_educativo: {
          nu_nivel_educativo: '',
          caracteristicas: {
            nombre: 'nu_nivel_educativo',
            label: 'Nivel educativo *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Introduzca el nivel educativo',
            tooltip: 'Nivel educativo del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },  
        tx_profesion: {
          tx_profesion: '',
          caracteristicas: {
            nombre: 'tx_profesion',
            label: 'Ocupación *',
            tipo: 'text',
            tamano: 'text-sm',
            placeholder: 'Introduzca la ocupación del paciente',
            tooltip: 'Ocupación del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },  
        nu_mano_dominante: {
          nu_mano_dominante: '',
          caracteristicas: {
            nombre: 'nu_mano_dominante',
            label: 'Mano dominante *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Introduzca mano dominante',
            tooltip: 'Mano dominante del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },  
      bo_es_menor: {
          bo_es_menor: 'false',
          caracteristicas: {
            nombre: 'bo_es_menor',
            label: 'El paciente es menor de edad? *',
            tipo: 'boolean',
            tamano: 'text-sm',
            placeholder: 'Seleccione una opción',
            tooltip: 'El paciente es menor de edad?',
            tooltip_pos:'bottom',
            required: 'required',        
            default: 'false'
        }
      },
      bo_posee_cedula: {
        bo_posee_cedula: 'false',
        caracteristicas: {
          nombre: 'bo_posee_cedula',
          label: 'Posee documento de identidad? *',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Seleccione una opción',
          tooltip: 'Posee documento de identidad?',
          tooltip_pos:'bottom',
          required: 'required',        
          default: 'true'
        }
      },
  
     },
     group_datos_ubigeo: {
        json_estado_co: {
          json_estado_co: '',
          caracteristicas: {
            nombre: 'json_estado_co',
            label: 'Entidad federal *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la entidad federal',
            tooltip: 'Entidad federal',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        json_municipio_co: {
          json_municipio_co: '',
          caracteristicas: {
            nombre: 'json_municipio_co',
            label: 'Municipio *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione el municipio',
            tooltip: 'Municipio',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        json_parroquia_co: {
          json_parroquia_co: '',
          caracteristicas: {
            nombre: 'json_parroquia_co',
            label: 'Parroquia *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la Parroquia',
            tooltip: 'Parroquia',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        tx_direccion: {
          tx_direccion: '',
          caracteristicas: {
            nombre: 'tx_direccion',
            label: 'Dirección *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Ingrese la dirección del paciente',
            tooltip: 'Dirección del paciente',
            tooltip_pos:'bottom',
            required: 'required',   
            default: ''
          }
        },
    },
    group_datos_redes_sociales: {
      tx_facebook: {
        tx_facebook: '',
        caracteristicas: {
          nombre: 'tx_facebook',
          label: 'Facebook',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese su cuenta en facebook',
          tooltip: 'Cuenta en facebook',
          tooltip_pos:'bottom',
          required: '',        
          default: ''
        }
      },
      tx_twitter: {
        tx_twitter: '',
        caracteristicas: {
          nombre: 'tx_twitter',
          label: 'Twitter',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese su cuenta en Twitter',
          tooltip: 'Cuenta en Twitter',
          tooltip_pos:'bottom',
          required: '',        
          default: ''
        }
      },
      tx_instagram: {
        tx_instagram: '',
        caracteristicas: {
          nombre: 'tx_instagram',
          label: 'Instagram',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese su cuenta en instagram',
          tooltip: 'Cuenta en instagram',
          tooltip_pos:'bottom',
          default: ''
        }
      },
    },
    group_datos_contacto: {
      tx_telefono_local: {
        tx_telefono_local: '',
        caracteristicas: {
          nombre: 'tx_telefono_local',
          label: 'Teléfono local',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese el teléfonico local',
          tooltip: 'Número teléfonico local',
          tooltip_pos:'bottom',
          default: ''
        }
      },
      tx_telefono_celular: {
        tx_telefono_celular: '',
        caracteristicas: {
          nombre: 'tx_telefono_celular',
          label: 'Teléfono celular',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese el teléfonico celular',
          tooltip: 'Número teléfonico celular',
          tooltip_pos:'bottom',
          default: ''
        }
      },
      tx_correo: {
        tx_correo: '',
        caracteristicas: {
          nombre: 'tx_correo',
          label: 'Correo electrónico',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese el correo electrónico',
          tooltip: 'Correo electrónico',
          tooltip_pos:'bottom',
          default: ''
        }
      },
    },
    tx_datos_representante: {
      tx_datos_representante: '',
      caracteristicas: {
        nombre: 'tx_datos_representante',
        label: 'datos del representante',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el correo electrónico',
        tooltip: 'Correo electrónico',
        tooltip_pos:'bottom',
        default: 'true'
      }
    }, 
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },
    btn_guardar: 'false',
    select_representante: '',
    isEditing: false,
  },
}

const getters = {
  fields_persona (state) {
    return state.fields_persona
  },  
  persona (state) {
    return state.persona
  }

}
const actions = {
  [LIST_PERSONA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPersona', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [MUESTRA_PERSONA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPersona', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_PERSONA] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSPersona/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }
  
}

const mutations = {
  [SET_PERSONA] (state, persona) {
    state.persona = persona
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
