import ApiService from '@/common/api.service'

import {
  MUESTRA_CONTACTO,
  REG_CONTACTO
} from '@/store/actions.type'

import {
//  SET_ERROR,
  SET_CONTACTO  ,
  SET_ERROR
} from '@/store/mutations.type'

const state = {
  contactos: [],
}


const getters = {
  contactos (state) {
    return state.contactos
  }

}
const actions = {
  [MUESTRA_CONTACTO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSContacto', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },  
  [REG_CONTACTO] (context, datos) {
    console.log('Registrar REG_COMENTARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSContacto/registrar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }, 
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_CONTACTO] (state, valor) {
    state.contactos = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
