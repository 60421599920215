import { extend } from 'vee-validate'
import { required, email, numeric, regex, min, max } from 'vee-validate/dist/rules'

extend('objectNotEmpty', {
    validate: (value) => {
      if (!value.producto) return false
      if (Object.keys(value.producto.code).length > 0) {
        return true
      }
      return false
    },
    message:
      'Debe seleccionar un valor.'
  })
  
  extend('regex', {
    ...regex,
    message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  regex digitos  seguido un guión, terminando con un digito'
  })
  
  extend('rif', {
    ...regex,
    message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  ocho(8)11 digitos  seguido un guión, terminando con un digito'
  })
  extend('tele', {
    ...regex,
    message: 'La primera letra debe se "V,G,J,C", seguido un guión, luego  ocho(8)222 digitos  seguido un guión, terminando con un digito'
  })
  extend('required', {
    ...required,
    message: 'Campo requerido'
  })
  
  extend('min', {
    ...min,
    message: 'Valor minimo 5'
  })
  
  extend('max', {
    ...max,
    message: 'Valor máximo 10'
  })
  
  extend('email', {
    ...email,
    message: 'Debe introducir un correo valido'
  })
  extend('numeric', {
    ...numeric,
    message: 'Debe introducir solo números'
  })
  
  const validacion = {

  }
  validacion.install = function (Vue) {
    Vue.prototype.$getConst = (key) => {
        return validacion[key];
    }
  };
  export default validacion;