export const CHECK_AUTH = 'checkAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'

// DESDE AQUI //
// MENU //
export const MUESTRA_MENU = 'muestraMenu'
// USUARIOS //
export const MUESTRA_USUARIO = 'muestraUsuario'
export const MUESTRA_USUARIO_USERNAME = 'muestraUsuarioUsername'
export const CREATE_USUARIO_01 = 'regUsuario01'
export const CREATE_USUARIO = 'regUsuario'
export const CREATE_USUARIO_TEMP = 'regUsuarioTemporal'
export const UPDATE_USUARIO = 'editUsuario'
export const UPDATE_USUARIO_TEMP = 'editUsuario'
export const REFRESCAR_USUARIO = 'refrescarUsuario'
export const VALIDA_USUARIO_TEMP = 'validaUsuarioTemp'
export const USUARIO_VALIDA = 'validaUsuario'
export const MUESTRA_USUARIO_PERSONA = 'muestraUsuarioPersona'
export const MUESTRA_USUARIO_ROLES = 'muestraUsuarioRoles'
export const LIST_ROLES = 'listRoles'
export const LIST_USUARIO = 'listUsuarios'
export const LIST_USUARIO_INST_PERFIL = 'listUsuarioInstPerfil'

// PERFILES //
export const LIST_PERFIL = 'listPerfiles'
export const UPDATE_PERFIL = 'editarPerfil'
export const CREATE_PERFIL = 'regPerfil'

// USUARIOA_PERFILES //
export const LIST_USUARIO_PERFIL = 'listUsuarioPerfiles'
export const UPDATE_USUARIO_PERFIL = 'updateUsuarioPerfil'
export const CREATE_USUARIO_PERFIL = 'createUsuarioPerfil'

// PERSONA //
export const MUESTRA_PERSONA = 'muestraPersona'
export const LIST_PERSONA = 'listPersona'
export const CREATE_PERSONA = 'regPersona'
export const MUESTRA_TIPO_CAPTACION = 'muestraTipoCaptacion'


// UBICACION GEOGRAFICA //
export const MUESTRA_CODPOSTAL  = 'muestraCodPostal'
export const MUESTRA_ENTIDAD    = 'muestraEntidad'
export const MUESTRA_MUNICIPIO  = 'muestraMunicipio'
export const MUESTRA_PARROQUIA  = 'muestraParroquia'
export const READ_PAIS  = 'readPais'
export const LIST_PAIS  = 'readPais'

// MODAL //
export const REG_MODAL = 'regModal'
// COMENTARIOS //
export const REG_COMENTARIO = 'regComentario'
// CITAS //
export const CREATE_CITA = 'regCita'
export const REG_RESULTADO_CITA = 'regResultado'
export const EDITAR_CITA = 'editarCita'
export const CANCELAR_CITA = 'cancelarCita'
export const MUESTRA_CITA = 'muestraCita'
export const MUESTRA_STATUS_CITA = 'muestraStatusCita'


// CLIENTE //
export const REG_CLIENTE = 'regCliente'
export const EDIT_CLIENTE = 'editCliente'
export const MUESTRA_CLIENTE = 'muestraCliente'
export const REFRESCAR_CLIENTE = 'refrescarCliente'
export const REFRESCAR_CLIENTE_LISTA = 'refrescarClienteLista'

export const MUESTRA_CLIENTE_LISTA = 'muestraClienteLista'

// CONTACTOS //
export const MUESTRA_CONTACTO = 'muestraContacto'
export const REG_CONTACTO = 'regContacto'

// SEGURO //
export const MUESTRA_SEGURO = 'muestraSeguro'
export const REG_SEGURO = 'regSeguro'
// SEGURO //
export const MUESTRA_VENTA = 'muestraVenta'
export const REG_VENTA = 'regVenta'

// ARCHIVOS //
export const UPLOAD = 'upLoadFile'
export const DOWNLOAD = 'downloadFile'
// Inmobiliarioa //
export const REG_INMUEBLE = 'regInmueble'
export const MUESTRA_INMUEBLE = 'muestraInmueble'
// REPRESENTANTE //
export const CREATE_REPRESENTANTE = 'createRepresentante'
export const READ_REPRESENTANTE = 'readRepresentante'
export const UPDATE_REPRESENTANTE = 'updateRepresentante'
export const LIST_REPRESENTANTE = 'listRepresentante'
// PACIENTE //
export const MUESTRA_PACIENTE = 'muestraPaciente'
export const MUESTRA_PACIENTE_LISTA = 'muestraPacienteLista'

export const CREATE_PACIENTE = 'createPaciente'
export const READ_PACIENTE = 'readPaciente'
export const UPDATE_PACIENTE = 'updatePaciente'
// CONSULTA MEDICA //
export const MUESTRA_CONSULTA_MEDICA = 'muestraConsultaMedica'
export const CREATE_CONSULTA_MEDICA = 'createConsultaMedica'
export const READ_CONSULTA_MEDICA = 'readConsultaMedica'
export const UPDATE_CONSULTA_MEDICA = 'updateConsultaMedica'
export const READ_CM_TRATAMIENTO = 'muestraCMTratamiento'
export const CREATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL = 'createConsultaMedicaEnfermedadActual'
export const UPDATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL = 'updateConsultaMedicaEnfermedadActual'
export const UPDATE_CONSULTA_MEDICA_ANTECEDENTES_ENFERMEDAD_ACTUAL = 'updateConsultaMedicaAntecedentesEnfermedadActual'
export const UPDATE_CONSULTA_MEDICA_ANTECEDENTES_PERSONALES = 'updateConsultaMedicaAntecedentesPersonales'



// ESPECIALIDAD //
export const READ_ESPECIALIDAD = 'readEspecialidad'
export const CREATE_ESPECIALIDAD = 'createEspecialidad'
export const UPDATE_ESPECIALIDAD = 'updateEspecialidad'
export const LIST_ESPECIALIDAD = 'listEspecialidad'

// ESPECIALIDAD USUARIO //
export const READ_ESPECIALIDAD_USUARIO_HORAS = 'readEspecialidadUsuarioHoras'
export const READ_ESPECIALIDAD_USUARIO = 'readEspecialidadUsuario'
export const CREATE_ESPECIALIDAD_USUARIO = 'createEspecialidadUsuario'
export const UPDATE_ESPECIALIDAD_USUARIO = 'updateEspecialidadUsuario'

// CENTRO MEDICO //
export const READ_CENTRO_MEDICO = 'readCentroMedico'
export const CREATE_CENTRO_MEDICO = 'createCentroMedico'
export const UPDATE_CENTRO_MEDICO = 'updateCentroMedico'
// MEDICO //
export const READ_MEDICO = 'readMedico'
export const CREATE_MEDICO = 'createMedico'
export const UPDATE_MEDICO = 'updateMedico'
// MEDICO //
export const READ_CENTRO_MEDICO_USUARIO = 'readCentroMedicoUsuario'
export const CREATE_CENTRO_MEDICO_USUARIO = 'createCentroMedicoUsuario'
export const UPDATE_CENTRO_MEDICO_USUARIO = 'updateCentroMedicoUsuario'
// MEDICO //
export const READ_TRATAMIENTO_AGENDA = 'readTratamientoAgenda'
export const READ_TRATAMIENTO = 'readTratamiento'
export const CREATE_TRATAMIENTO = 'createTratamiento'
export const UPDATE_TRATAMIENTO = 'updateTratamiento'
// visita MEDICa //
export const CREATE_VISITA_MEDICA = 'createVisitaMedica'
export const READ_VISITA_MEDICA = 'readVisitaMedica'
export const UPDATE_VISITA_MEDICA = 'updateVisitaMedica'

// laboratorio //
export const CREATE_LABORATORIO = 'createLaboratorio'
export const READ_LABORATORIO = 'readLaboratorio'
export const UPDATE_LABORATORIO = 'updateLaboratorio'
// radiologia //
export const CREATE_RADIOLOGIA = 'createRadiologia'
export const READ_RADIOLOGIA = 'readRadiologia'
export const UPDATE_RADIOLOGIA = 'updateRadiologia'
// agenda //
export const READ_AGENDA_VISITAS = 'readAgendaVisitas'
export const READ_AGENDA_CONSULTAS = 'readAgendaConsulta'
// Historia Clinica //
export const READ_HISTORIA_CLINICA = 'readHistoriaClinica'
export const CREATE_HISTORIA_CLINICA = 'createHistoriaClinica'
export const UPDATE_HISTORIA_CLINICA = 'updateHistoriaClinica'
// CONFIGURACION //
export const READ_INSTITUCION = 'readInstitucion'
export const CREATE_INSTITUCION = 'createInstitucion'
export const UPDATE_INSTITUCION = 'updateInstitucion'
export const LIST_INSTITUCION = 'listInstitucion'
export const LIST_TIPO_PERSONA = 'listTipoPersona'
export const CREATE_INSTITUCION_WIZARD = 'createInstitucionWizard'
export const LIST_ANOS = 'listAnos'
export const LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREA = 'listIntitucionNivelPlanGradoArea'
export const LIST_ANO_NIVELES = 'listAnoNiveles'
export const CREATE_ASOCIAR_INSTITUCION = 'createAsociarInstitucion'

export const CREATE_ACADEMICO_WIZARD = 'createAcademicaWizard'
export const CREATE_UNIDAD = 'createUnidad'
export const LIST_UNIDAD = 'listUnidad'

export const LIST_NIVELES = 'listNiveles'
export const LIST_PLANES = 'listPlanes'
export const LIST_GRADOS = 'listGrados'
export const LIST_CONTENIDO = 'listContenido'

export const LIST_AREA_FORMACION = 'listAreaFormacion'
export const LIST_AREAS_FORMACION = 'listAreasFormacion'
export const LIST_INSTITUCION_NIVELES = 'listInstitucionNiveles'
export const LIST_INSTITUCION_NIVEL_PLAN_GRADOS = 'listInstitucionGrados'
export const LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREAS = 'listInstitucionNivelPlanGradoAreas'
export const LIST_PLAN_AREAS = 'listPlanAreas'
export const LIST_PLAN_GRADOS = 'listPlanGrados'
export const LIST_OFERTA_ACADEMICA = 'listOfertaAcademica'
export const CREATE_AREA_FORMACION = 'createAreaFormacion'

export const CREATE_EVALUACION = 'createEvaluacion'
export const READ_EVALUACION = 'readEvaluacion'
export const UPDATE_EVALUACION = 'updateEvaluacion'
export const LIST_EVALUACION = 'listEvaluacion'
export const LIST_EVALUACION_NIVELES = 'listEvaluacionNiveles'
export const LIST_EVALUACION_GRADOS = 'listEvaluacionGrados'
export const CREATE_EVALUACION_WIZARD = 'createEvaluacionWizard'
export const LIST_PLAN_EVALUACION_AREA = 'listPlanEvaluacionArea'

export const CREATE_INDICADOR_LOGRO = 'createIndicadorLogro'
export const READ_INDICADOR = 'readIndicador'
export const UPDATE_INDICADOR = 'updateIndicador'
export const LIST_INDICADORES_LOGRO = 'listIndicador'
export const LIST_INDICADORES_LOGRO_NIVELES = 'listIndicadorLogroNiveles'
export const LIST_INDICADORES_LOGRO_GRADOS = 'listIndicadorLogroGrados'
export const CREATE_INDICADOR_LOGRO_WIZARD = 'createIndicadorLogroWizard'

export const CREATE_COMPETENCIA = 'createCompetencia'
export const READ_COMPETENCIA = 'readCompetencia'
export const UPDATE_COMPETENCIA = 'updateCompetencia'
export const LIST_COMPETENCIAS = 'listCompetencia'
export const LIST_COMPETENCIAS_NIVELES = 'listCompetenciaNiveles'
export const LIST_COMPETENCIAS_GRADOS = 'listCompetenciaGrados'
export const CREATE_COMPETENCIA_WIZARD = 'createCompetenciaWizard'

export const CREATE_LAPSO = 'createLapso'
export const READ_LAPSO = 'readLapso'
export const UPDATE_LAPSO = 'updateLapso'
export const LIST_LAPSO = 'listLapso'
export const ASOCIAR_UNIDAD_LAPSO = 'asociarUnidadLapso'

export const CREATE_PLAN_EVALUACION_WIZARD = 'createPlanEvaluacion'
export const LIST_PLAN_EVALUACION = 'listPlanEvaluacion'

export const CREATE_CALIFICACION = 'createCalificacion'
export const READ_CALIFICACION = 'readCalificacion'
export const UPDATE_CALIFICACION = 'updateCalificacion'
export const LIST_CALIFICACION = 'listCalificacion'

export const CREATE_ESCALA_VALORES = 'createEscalaValores'
export const READ_ESCALA_VALORES = 'readEscalaValores'
export const UPDATE_ESCALA_VALORES = 'updateEscalaValores'
export const LIST_ESCALA_VALORES = 'listEscalaValores'


