import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt.service'

// import auth from '@/store/auth.module'

import {
  API_URL,
  API_URL_8088
} from '@/common/config'
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = API_URL
const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },

  setHeader () {
    //var username = 'user';
    //var password = 'password';
    // var basicAuth = 'Basic ' + btoa(username + ':' + password);
    Vue.axios.defaults.withCredentials = true
    // Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' // for all requests
    // Vue.axios.defaults.headers.common.Authorization = 'Basic ' + basicAuth
    /* Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' // for all requests
    Vue.axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS' // for all requests
    Vue.axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'accept,content-type' // for all requests
    Vue.axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true' // for all requests
    Vue.axios.defaults.headers.common['accept'] = 'application/json' // for all requests
  */
 
    //Vue.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded' // for all requests
    //Vue.axios.defaults.headers.common['accept'] = 'application/x-www-form-urlencoded' // for all requests
  },

  query (resource, params, options) {
    console.log('ApiService query: ' + JwtService.getToken())
    params.ticket = `${JwtService.getToken()}`
    console.log('resource: ', params)
    return Vue.axios.get(resource, {
      params: params
    }, options).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },
  get (resource, params)  {
    params.ticket = `${JwtService.getToken()}`
    params.username = window.localStorage.getItem("user")

    //    const headers = { "Content-Type": "application/x-www-form-urlencoded" }
    return Vue.axios.get(`${resource}`, { params } ).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
    // GET request using fetch with set headers
    /*const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const aa = fetch(API_URL + `${resource}?` + `ticket=${JwtService.getToken()}`, { headers })
    return aa;
    */
    //console.log('ApiService query getSelect: ', aa)
    
  },
  getSelect (resource, params) {
    console.log('ApiService query getSelect: ', resource, params)
    params.ticket = `${JwtService.getToken()}`
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    return Vue.axios.get(`${resource}?ticket=` + `${JwtService.getToken()}`, {
      headers
    })
  },
  post (resource, params) {
    console.log('Post ', resource, params)

    params.ticket = `${JwtService.getToken()}`
    params.username = window.localStorage.getItem("user")

    const options = { 
     params: params
    };    
    
   // options.ticket = `${JwtService.getToken()}`
   //const headers = { 
   //  "Content-Type": "application/x-www-form-urlencoded"
   //};    
    //console.log('Paso Api service ', resource, params)
    return Vue.axios.post(`${resource}`, params, options )
    // return Vue.axios.post(`${resource}`, params , { headers })
  },

  update (resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put (resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete (resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiService

export const ApiLogin = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },
  setHeader () {
    //var username = 'user';
    //var password = 'password';
    // var basicAuth = 'Basic ' + btoa(username + ':' + password);
    Vue.axios.defaults.withCredentials = true
    // Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' // for all requests
    // Vue.axios.defaults.headers.common.Authorization = 'Basic ' + basicAuth
    /* Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' // for all requests
    Vue.axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS' // for all requests
    Vue.axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'accept,content-type' // for all requests
    Vue.axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true' // for all requests
    Vue.axios.defaults.headers.common['accept'] = 'application/json' // for all requests
  */
 
    //Vue.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded' // for all requests
    //Vue.axios.defaults.headers.common['accept'] = 'application/x-www-form-urlencoded' // for all requests
  },
  get (resource, params)  {
    //    const headers = { "Content-Type": "application/x-www-form-urlencoded" }
    return Vue.axios.get(`${resource}`, { params } ).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
    // GET request using fetch with set headers
    /*const headers = { "Content-Type": "application/x-www-form-urlencoded" };
    const aa = fetch(API_URL + `${resource}?` + `ticket=${JwtService.getToken()}`, { headers })
    return aa;
    */
    //console.log('ApiService query getSelect: ', aa)
    
  },
  post (resource, params) {
    console.log('Paso Api service ', ...params.entries())
    return Vue.axios.post(`${resource}`, params)
  },
  destroy (slug, commentId) {
    return ApiLogin.delete(`articles/${slug}/comments/${commentId}`)
  }
}

export const UbiGeoService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL_8088
  },
  get (resource, params) {
    console.log('resource getSelect RecoleccionService: ', params, resource)
    return ApiService.getSelect(`${resource}`, params)
  },
  post (resource, params) {
    console.log('Paso Api service ' + resource)
    params.append('ticket', `${JwtService.getToken()}`)
    return Vue.axios.post(`${resource}`, params)
  },
  destroy (slug, commentId) {
    return ApiService.delete(`articles/${slug}/comments/${commentId}`)
  }
}




export const MenuesService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = API_URL
  },
  query (resource, params) {
    params.ticket = {
      ticket: `${JwtService.getToken()}`
    }
    return Vue.axios.get(resource, {
      params: params.ticket
    }).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },
  get (resource, params, options) {
    params.ticket = `${JwtService.getToken()}`
    console.log('Paso Api service ', resource, params, options)
    return Vue.axios.get(`${resource}`, {
      params: params
    }).catch(error => {
      console.log('Api error', error)
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}
