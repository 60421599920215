import ApiService from '@/common/api.service'
import {
  CREATE_LAPSO,
  READ_LAPSO,
  UPDATE_LAPSO,
  LIST_LAPSO,
} from './actions.type'
import {
  SET_LAPSO,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_lapsos: {
    btn_guardar: 'false',
    isEditing: false,
    group_datos_basicos: {    
      json_group_datos_basicos: {
        json_group_datos_basicos: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_group_datos_basicos',
          label: 'Datos basicos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_ano_escolar',
          label: 'Año escolar  *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Año escolar',
          tooltip: 'Año escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'Lapso id  *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_lapso: {
        nu_id_lapso: '',
        caracteristicas: {
          nombre: 'nu_id_lapso',
          label: 'Lapso id  *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_lapso: {
        co_lapso: '',
        caracteristicas: {
          nombre: 'co_lapso',
          label: 'Código lapso *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código del lapso',
          tooltip: 'Código del lapso',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_nombre_lapso: {
        tx_nombre_lapso: '',
        caracteristicas: {
          nombre: 'tx_nombre_lapso',
          label: 'Nombre del lapso *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca nombre del lapso',
          tooltip: 'Lapso',
          required: 'required',        
          default: ''
        }
      },
      fe_inicio_lapso: {
        fe_inicio_lapso: '',
        caracteristicas: {
          nombre: 'fe_inicio_lapso',
          label: 'Fecha de inicio del lapso *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese Fecha de inicio del lapso',
          tooltip: 'Fecha de inicio del lapso',
          required: 'required',        
          default: ''
        }
      },
      fe_fin_lapso: {
        fe_fin_lapso: '',
        caracteristicas: {
          nombre: 'fe_fin_lapso',
          label: 'Fecha fin del lapso *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese Fecha fin del lapso',
          tooltip: 'Fecha fin del lapso',
          required: 'required',        
          default: ''
        }
      },
      co_estatus_lapso: {
        co_estatus_lapso: '',
        caracteristicas: {
          nombre: 'co_estatus_lapso',
          label: 'Código de estatus del lapso *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de estatus del lapso',
          tooltip: 'Código de estatus del lapso',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  evaluacion: null,

}

const getters = {  
  fields_lapsos (state) {
    return state.fields_lapsos
  },

}
const actions = {
  [LIST_LAPSO] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_lapso
    return new Promise((resolve, reject) => {
      ApiService.get('WSLapsos', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_LAPSO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSLapso', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_LAPSO] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSLapso/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_LAPSO] (context, datos) {
    console.log('Registrar UPDATE_LAPSO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSLapso/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_LAPSO] (state, lapso) {
    state.lapso = lapso
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
