import ApiService from '@/common/api.service'
import {
  READ_CM_TRATAMIENTO,
  MUESTRA_CONSULTA_MEDICA,
  CREATE_CONSULTA_MEDICA,
  UPDATE_CONSULTA_MEDICA,
  UPDATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL,
  CREATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL,
  UPDATE_CONSULTA_MEDICA_ANTECEDENTES_ENFERMEDAD_ACTUAL,
  UPDATE_CONSULTA_MEDICA_ANTECEDENTES_PERSONALES
} from './actions.type'
import {
  SET_CONSULTA_MEDICA,
  SET_ERROR
} from './mutations.type'

const state = {
  consulta_medica: null,
  fields_consulta_medica: {
    fe_nacimiento: {
      fe_nacimiento: '',
      caracteristicas: {
        nombre: 'fe_nacimiento',
        label: 'Fecha de consulta *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Fecha de consulta',
        tooltip: 'Fecha de consulta',
        tooltip_pos:'bottom',
        required: 'required',        
        default: '',
        disabled: true
      }
    },
    nu_sexo: {
      nu_sexo: '',
      caracteristicas: {
        nombre: 'nu_sexo',
        label: 'Centro médico *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Ingrese el Centro médico',
        tooltip: 'Centro médico',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    tx_apellidos: {
      tx_apellidos: '',
      caracteristicas: {
        nombre: 'tx_apellidos',
        label: 'Centro médico *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Ingrese el Centro médico',
        tooltip: 'Centro médico',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    tx_nombres: {
      tx_nombres: '',
      caracteristicas: {
        nombre: 'tx_nombres',
        label: 'Centro médico *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Ingrese el Centro médico',
        tooltip: 'Centro médico',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },    
    nu_id_paciente: {
      nu_id_paciente: '',
      caracteristicas: {
        nombre: 'nu_id_paciente',
        label: 'Centro médico *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Ingrese el Centro médico',
        tooltip: 'Centro médico',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    nu_id_consulta: {
      nu_id_consulta: '',
      caracteristicas: {
        nombre: 'nu_id_consulta',
        label: 'Centro médico *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Ingrese el Centro médico',
        tooltip: 'Centro médico',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    json_centro_medico: {
      json_centro_medico: '',
      caracteristicas: {
        nombre: 'json_centro_medico',
        label: 'Centro médico *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Ingrese el Centro médico',
        tooltip: 'Centro médico',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    json_especialidad: {
      json_especialidad: '',
      caracteristicas: {
        nombre: 'json_especialidad',
        label: 'Especialidad *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Ingrese la especialidad',
        tooltip: 'Especialidad',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    fe_consulta: {
      fe_consulta: '',
      caracteristicas: {
        nombre: 'fe_consulta',
        label: 'Fecha de consulta *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Fecha de consulta',
        tooltip: 'Fecha de consulta',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    hh_consulta: {
      hh_consulta: '',
      caracteristicas: {
        nombre: 'hh_consulta',
        label: 'Hora de consulta *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Hora de consulta',
        tooltip: 'Hora de consulta',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    nu_tiempo_estimado: {
      nu_tiempo_estimado: '',
      caracteristicas: {
        nombre: 'nu_tiempo_estimado',
        label: 'Tiempo estimado de la consulta *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione el Tiempo estimado de la consulta',
        tooltip: 'Tiempo estimado de la consulta',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    tx_motivo: {
      tx_motivo: '',
      caracteristicas: {
        nombre: 'tx_motivo',
        label: 'Motivo de la consulta *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el motivo de la consulta',
        tooltip: 'Motivo de la consulta',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    co_status_consulta_medica: {
      co_status_consulta_medica: 'AGE_PRO',
      caracteristicas: {
        nombre: 'co_status_consulta_medica',
        label: 'Piso *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el piso donde esta ubicada la habitación',
        tooltip: 'Ingrese el piso donde esta ubicada la habitación',
        tooltip_pos:'bottom',
        required: 'required',        
        default: 'AGE_PRO'
      }
    },    
    btn_guardar: 'false',
    isEditing: false,  
  }
}

const getters = {
  fields_consulta_medica (state) {
    return state.fields_consulta_medica
  },  
  consulta_medica (state) {
    return state.consulta_medica
  }

}
const actions = {
  [MUESTRA_CONSULTA_MEDICA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSConsultaMedica', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_CM_TRATAMIENTO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSConsultaMedicaTratamiento', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_CONSULTA_MEDICA] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedica/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_CONSULTA_MEDICA] (context, datos) {
    console.log('Registrar UPDATE_CONSULTA_MEDICA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedica/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },  
  [CREATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedicaEnfermedadActual/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_CONSULTA_MEDICA_ENFERMEDAD_ACTUAL] (context, datos) {
    console.log('Registrar UPDATE_CONSULTA_MEDICA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedicaEnfermedadActual/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_CONSULTA_MEDICA_ANTECEDENTES_ENFERMEDAD_ACTUAL] (context, datos) {
    console.log('Registrar UPDATE_CONSULTA_MEDICA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedicaAntecedentesEnfermedadActual/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },      
  [UPDATE_CONSULTA_MEDICA_ANTECEDENTES_PERSONALES] (context, datos) {
    console.log('Registrar UPDATE_CONSULTA_MEDICA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedicaAntecedentesPersonales/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }        
}

const mutations = {
  [SET_CONSULTA_MEDICA] (state, consulta_medica) {
    state.consulta_medica = consulta_medica
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
