import ApiService from '@/common/api.service'
import {
  READ_MEDICO,
  CREATE_MEDICO,
  UPDATE_MEDICO
} from './actions.type'
import {
  SET_MEDICO,
  SET_ERROR
} from './mutations.type'

const state = {
  medico: null
}

const getters = {
  medico (state) {
    return state.medico
  }

}
const actions = {
  [READ_MEDICO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEspecialidad', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_MEDICO] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedica/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_MEDICO] (context, datos) {
    console.log('Registrar UPDATE_MEDICO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSMedico/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_MEDICO] (state, medico) {
    state.medico = medico
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
