const ID_TOKEN_KEY = 'JWT__access__token'
const USERNAME = 'JWT__access__username'
const PERFILES = 'JWT__access__perfiles'

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}
export const getUsername = () => {
  return window.localStorage.getItem(USERNAME)
}
export const getPerfiles = () => {
  return window.localStorage.getItem(PERFILES)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}
export const saveUsername = username => {
  window.localStorage.setItem(USERNAME, username)
}

export const savePerfiles = perfiles => {
  window.localStorage.setItem(PERFILES, perfiles)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken,saveUsername, getUsername, destroyToken, getPerfiles, savePerfiles }
