import ApiService from '@/common/api.service'
import {
  READ_ESPECIALIDAD_USUARIO_HORAS,
  READ_ESPECIALIDAD_USUARIO,
  CREATE_ESPECIALIDAD_USUARIO,
  UPDATE_ESPECIALIDAD_USUARIO
} from './actions.type'
import {
  SET_ESPECIALIDAD_USUARIO,
  SET_ERROR
} from './mutations.type'

const state = {
  especialidad_usuario: null,
  fields_especialidad_usuario: {
    json_especialidad: {
      json_especialidad: '',
      caracteristicas: {
        nombre: 'json_especialidad',
        label: 'Especialidad *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione la especialidad',
        tooltip: 'Especialidad',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
  }
}

const getters = {
  fields_especialidad_usuario (state) {
    return state.fields_especialidad_usuario
  },
  especialidad_usuario (state) {
    return state.especialidad_usuario
  }

}
const actions = {
  [READ_ESPECIALIDAD_USUARIO_HORAS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEspecialidadUsuarioHoras', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_ESPECIALIDAD_USUARIO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEspecialidadUsuario', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_ESPECIALIDAD_USUARIO] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedica/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_ESPECIALIDAD_USUARIO] (context, datos) {
    console.log('Registrar UPDATE_ESPECIALIDAD_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSConsultaMedica/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_ESPECIALIDAD_USUARIO] (state, especialidad_usuario) {
    state.especialidad_usuario = especialidad_usuario
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
