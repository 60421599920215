import ApiService from '@/common/api.service'
import { ApiLogin } from '@/common/api.service'
import {
  UPDATE_USUARIO_PERFIL,
  CREATE_USUARIO_PERFIL,
  LIST_USUARIO_PERFIL,
} from './actions.type'
import {
  SET_USUARIO,
  SET_ERROR,
  SET_USUARIO_REGISTRADO  
} from './mutations.type'

const state = {
  usuario: [],
  errores: null,
  usuarioRegistrado: false,
  fields_usuario_perfil: {
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },
    btn_guardar: 'false',
    isEditing: false,    
    group_datos_basicos: {    
      // desde      
      json_institucion_perfil: {
        json_institucion_perfil: [],
        caracteristicas: {
          nombre: 'json_institucion_perfil',
          label: 'Institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione los centros meédico',
          tooltip: 'Centros Médicos',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_usuario_perfil: {
        json_usuario_perfil: [],
        caracteristicas: {
          nombre: 'json_usuario_perfil',
          label: 'Perfiles del usuario *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione los perfiles del usuario',
          tooltip: 'Perfiles',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_username: {
        tx_username: '',
        caracteristicas: {
          nombre: 'tx_username',
          label: 'Perfiles del usuario *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Seleccione los perfiles del usuario',
          tooltip: 'Perfiles',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },

    },  
  },
}

const getters = {
  fields_usuario_perfil (state) {
    return state.fields_usuario_perfil
  },    
  usuarios (state) {
    return state.cliente    
  },
  usuarioRegistrado (state) {
    return state.usuarioRegistrado
    
  }


}
const actions = {
  [UPDATE_USUARIO_PERFIL] (context, datos) {
    console.log('Registrar REG_USUARIO: ', ...datos.entries())
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiLogin.post('/WSPerfil/registro_temp',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response)
          reject(response)
        })
    })
  },
  [CREATE_USUARIO_PERFIL] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSUsuario/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },    
  [LIST_USUARIO_PERFIL] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSUsuario', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [UPDATE_USUARIO_PERFIL] (context, datos) {
    console.log('Registrar EDIT_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCliente/editar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
}

const mutations = {
  [SET_USUARIO] (state, datos) {
    state.cliente = datos
  },
  [SET_USUARIO_REGISTRADO] (state, datos) {
    state.usuarioRegistrado = datos
  },

}

export default {
  state,
  actions,
  mutations,
  getters
}
