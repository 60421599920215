import ApiService from '@/common/api.service'
import {
  MUESTRA_CODPOSTAL,
  MUESTRA_ENTIDAD,
  MUESTRA_MUNICIPIO,
  MUESTRA_PARROQUIA,
  LIST_PAIS
} from '@/store/actions.type'

import {
  SET_COD_POSTALES
} from '@/store/mutations.type'

const state = {
  cod_postales: [],
  entidades: [],
}


const getters = {
  cod_postales (state) {
    return state.cod_postales
  },
  entidades (state) {
    return state.entidades
  }
}
const actions = {
  [MUESTRA_CODPOSTAL] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSUbiGeo/cod_postal', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [MUESTRA_ENTIDAD] (context, datos) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('WSEntidad/getEntidad', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [MUESTRA_MUNICIPIO] (context, datos) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('WSMunicipio/getMunicipio', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [MUESTRA_PARROQUIA] (context, datos) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('WSParroquia/getParroquia', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },                              
  [LIST_PAIS] (context, datos) {
    ApiService.init()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPais/getPais', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  }                              
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_COD_POSTALES] (state, valor) {
    state.cod_postales = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
