import ApiService from '@/common/api.service'
import {
  CREATE_INSTITUCION,
  READ_INSTITUCION,
  UPDATE_INSTITUCION,
  LIST_INSTITUCION,
  LIST_INSTITUCION_NIVELES,
  LIST_INSTITUCION_NIVEL_PLAN_GRADOS,
  LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREAS,
  CREATE_INSTITUCION_WIZARD
} from './actions.type'
import {
  SET_INSTITUCION,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_institucion: {
    btn_guardar: 'false',
    list_instituciones: [],
    isEditing: false,
    group_datos_basico: {    
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'Persona *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_rif: {
        tx_rif: '',
        caracteristicas: {
          nombre: 'tx_rif',
          label: 'Rif *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el RIF',
          tooltip: 'Rif',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_razon_social: {
        tx_razon_social: '',
        caracteristicas: {
          nombre: 'tx_razon_social',
          label: 'Razón social *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese Introduzca la razón social',
          tooltip: 'Razón social',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_comercial: {
        tx_nombre_comercial: '',
        caracteristicas: {
          nombre: 'tx_nombre_comercial',
          label: 'Nombre comercial *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese introduzca nombre comercial',
          tooltip: 'Nombre comercial',
          required: 'required',        
          default: ''
        }
      },
      co_plantel: {
        co_plantel: '',
        caracteristicas: {
          nombre: 'co_plantel',
          label: 'Código del plantel *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese introduzca código del plantel',
          tooltip: 'Código del plantel',
          required: 'required',        
          default: ''
        }
      },
      tx_zona_educativa: {
        tx_zona_educativa: '',
        caracteristicas: {
          nombre: 'tx_zona_educativa',
          label: 'Zona educativa *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese introduzca la zona educativa',
          tooltip: 'Zona educativa',
          required: 'required',        
          default: ''
        }
      },
      tx_distrito_escolar: {
        tx_distrito_escolar: '',
        caracteristicas: {
          nombre: 'tx_distrito_escolar',
          label: 'Distrito escolar *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese el código del distrito escolar',
          tooltip: 'Distrito escolar',
          required: 'required',        
          default: ''
        }
      },
      json_tipo_institucion: {
        json_tipo_institucion: [],
        caracteristicas: {
          nombre: 'json_tipo_institucion',
          label: 'Tipo de institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el tipo de institución',
          tooltip: 'Tipo de institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },  
    },
    group_datos_ubigeo: {    
      json_pais: {
        json_pais: [],
        caracteristicas: {
          nombre: 'json_pais',
          label: 'País *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el país',
          tooltip: 'País',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_estado: {
        json_estado: [],
        caracteristicas: {
          nombre: 'json_estado',
          label: 'Entidad federal *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la entidad federal',
          tooltip: 'Entidad federal',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_municipio: {
        json_municipio: [],
        caracteristicas: {
          nombre: 'json_municipio',
          label: 'Municipio *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el municipio',
          tooltip: 'Municipio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_parroquia: {
        json_parroquia: [],
        caracteristicas: {
          nombre: 'json_parroquia',
          label: 'Parroquia *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_direccion: {
        tx_direccion: '',
        caracteristicas: {
          nombre: 'tx_direccion',
          label: 'Dirección *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Ingrese la dirección del paciente',
          tooltip: 'Dirección del paciente',
          tooltip_pos:'bottom',
          required: 'required',   
          default: ''
        }
      },
    },
    group_contacto_institucional: {          
      json_ano_institucion_contacto: {
        json_ano_institucion_contacto: [],
        caracteristicas: {
          nombre: 'json_ano_institucion_contacto',
          label: 'json_ano_institucion_contacto',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Ingrese número de teléfóno celular',
          tooltip: 'Número de teléfóno celular',
          tooltip_pos:'bottom',
          required: '',        
          default: ''
        }
      },

    }, 
    group_personal_contacto: {    
      json_ano_institucion_personal_contacto: {
        json_ano_institucion_personal_contacto: [],
        caracteristicas: {
          nombre: 'json_ano_institucion_personal_contacto',
          label: 'json_ano_institucion_personal_contacto',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Ingrese número de teléfóno celular',
          tooltip: 'Número de teléfóno celular',
          tooltip_pos:'bottom',
          required: '',        
          default: ''
        }
      },
    }, 
    group_terminos: {    
      json_ano_institucion_terminos: {
        json_ano_institucion_terminos: [],
        caracteristicas: {
          nombre: 'json_ano_institucion_terminos',
          label: 'json_ano_institucion_terminos',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Ingrese número de teléfóno celular',
          tooltip: 'Número de teléfóno celular',
          tooltip_pos:'bottom',
          required: '',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  institucion: null,

}

const getters = {
  
  fields_institucion (state) {
    return state.fields_institucion
  },
  institucion (state) {
    return state.institucion
  },
  list_instituciones (state) {
    return state.list_instituciones
  }

}
const actions = {
  [CREATE_INSTITUCION_WIZARD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSInstitucionWizard/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_INSTITUCION] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_institucion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_INSTITUCION_NIVEL_PLAN_GRADOS] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_institucion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucionNivelPlanGrado', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_INSTITUCION_NIVEL_PLAN_GRADO_AREAS] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_institucion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucionNivelPlanGradoAreas', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_INSTITUCION_NIVELES] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_institucion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucionNivelPlan', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_INSTITUCION] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_INSTITUCION] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSInstitucion/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_INSTITUCION] (context, datos) {
    console.log('Registrar UPDATE_INSTITUCION: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSInstitucion/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_INSTITUCION] (state, institucion) {
    state.institucion = institucion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
