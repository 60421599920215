<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu border-r-2 border-gray-100"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="flex justify-center p-2 h-auto w-auto">
      <div class="w-1/2 mt-2">
        <router-link
          to="/"
          class="d-flex align-center text-decoration-none"
        >
          <img :src="require('@/assets/images/logos/logoAkdemia_externo.png')"  class="max-w-full h-auto rounded-lg" alt="Santiago" />
        </router-link>
        
      </div>
    </div>

    <!-- Navigation Items
      shaped
      class="vertical-nav-menu-items pr-5"
      expand
       -->      
    <v-list
    > 
      <div class="border-t-2" v-for="(item, index) in menues" :key="index">
        <div v-if="item.hijo">

              <nav-menu-group
                :title=item.tx_desc_nombre
                :icon=icons[item.im_icono]
              >
            <div class="border-t-2" v-for="(item_1, nu_orden) in item.hijo " :key="nu_orden">
                <nav-menu-link
                  :title=item_1.tx_desc_nombre
                  :to="{ name: item_1.tx_url, params: { id_menu: item_1.nu_id_opcion } }"
                  :icon=icons[item_1.im_icono]
                ></nav-menu-link>
            </div>

              </nav-menu-group>

        </div>
        <div v-else>
          <nav-menu-link
            :title=item.tx_desc_nombre
            :to="{ name: item.tx_url, params: { id_menu: item.nu_id_opcion } }"
            :icon=icons[item.im_icono]
          ></nav-menu-link>

        </div>

      </div>
 
    </v-list>

    <!--<a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/consulta-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    -->

    <!--</a>-->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiViewAgendaOutline,
  mdiViewDashboard,
  mdiAccount,
  mdiHeadDotsHorizontalOutline,
  mdiNoteSearch,
  mdiAccountLock,
  mdiAccountBoxOutline,
  mdiViewAgenda,
  mdiChartTimeline
} from '@mdi/js'
//import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { reactive  } from "@vue/composition-api"
import { useVuex } from '@vueblocks/vue-use-vuex'

export default {
  components: {
    //NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    const icons = reactive({
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiViewAgendaOutline,
        mdiAccount,
        mdiViewDashboard,
        mdiHeadDotsHorizontalOutline,
        mdiNoteSearch,
        mdiAccountLock,
        mdiAccountBoxOutline,
        mdiViewAgenda,
        mdiChartTimeline
      });  
    const { useGetters } = useVuex()    


    return {
      ...useGetters([
        'currentUser',
        'menues'
        // ...
      ]),         
      icons
    }
  },
  methods: {

  },  
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
