import ApiService from '@/common/api.service'
import {
  LIST_PERFIL,
  LIST_ROLES,
  UPDATE_PERFIL,
  CREATE_PERFIL
} from './actions.type'
import {
  SET_PERFIL,
  SET_ERROR,
} from './mutations.type'

const state = {
  perfil: [],
  errores: null,
  perfilRegistrado: false,
  fields_perfil: {
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },
    btn_guardar: 'false',
    isEditing: false,    
    group_datos_basicos: {    
      // desde
      nu_id_perfil: {
        nu_id_perfil: '',
        caracteristicas: {
          nombre: 'nu_id_perfil',
          label: 'Perfil *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Nombre del perfil',
          tooltip: 'Perfil',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_perfil: {
        tx_nombre_perfil: '',
        caracteristicas: {
          nombre: 'tx_nombre_perfil',
          label: 'Perfil *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Nombre del perfil',
          tooltip: 'Perfil',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_desc_perfil: {
        tx_desc_perfil: '',
        caracteristicas: {
          nombre: 'tx_desc_perfil',
          label: 'Breve descripción *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Breve descripción',
          tooltip: 'Descripción',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_opcion: {
        json_opcion: [],
        caracteristicas: {
          nombre: 'json_opcion',
          label: 'Seleccione las opciones para el perfil *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Breve descripción',
          tooltip: 'Descripción',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      sw_status: {
        sw_status: '',
        caracteristicas: {
          nombre: 'sw_status',
          label: 'Usuario activo *',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Seleccione el usuario activo',
          tooltip: 'Usuario activo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }  
      },      
    },
  },

}

const getters = {
  fields_perfil (state) {
    return state.fields_perfil
  },    
  perfils (state) {
    return state.cliente    
  },
  perfilRegistrado (state) {
    return state.perfilRegistrado
    
  }


}
const actions = {
  [LIST_ROLES] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSRoles', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_PERFIL] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSPerfil', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_PERFIL] (context, datos) {
    console.log('Registrar EDIT_PERFIL: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSPerfil/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_PERFIL, data.perfil)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },  
  [UPDATE_PERFIL] (context, datos) {
    console.log('Registrar EDIT_PERFIL: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSPerfil/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_PERFIL, data.perfil)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
}

const mutations = {
  [SET_PERFIL] (state, datos) {
    state.cliente = datos
  },

}

export default {
  state,
  actions,
  mutations,
  getters
}
