//import ApiService from '@/common/api.service'
import {
} from './actions.type'
import {
} from './mutations.type'

const state = {
  fields_oferta_academica: {
    btn_guardar: 'false',
    list_instituciones: [],
    isEditing: false,
    group_datos_basicos: {    
      json_ano_institucion: {
        json_ano_institucion: [],
        caracteristicas: {
          nombre: 'json_ano_institucion',
          label: 'Institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_ano_escolar',
          label: 'Año escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_ano: {
        tx_nombre_ano: '',
        caracteristicas: {
          nombre: 'tx_nombre_ano',
          label: 'Año escolar *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el año escolar',
          tooltip: 'Año escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_institucion: {
        json_institucion: '',
        caracteristicas: {
          nombre: 'json_institucion',
          label: 'Institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_lapsos: {
        json_lapsos: '',
        caracteristicas: {
          nombre: 'json_lapsos',
          label: 'Lapsos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el lapso',
          tooltip: 'Lapsos',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_nivel: {
        json_nivel: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_nivel',
          label: 'Nivel educativo *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del nivel educativo',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_plan_estudio: {
        json_plan_estudio: '',
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_plan_estudio',
          label: 'Código del plan de estudio *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca el plan de estudio',
          tooltip: 'Plan de estudio',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_grados: {
        json_grados: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_grados',
          label: 'Nivel/Grado/Año *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Nivel/Grado/Año',
          tooltip: 'Nivel/Grado/Año',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_areas: {
        json_areas: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_areas',
          label: 'Área de formación *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el área de formación',
          tooltip: 'Área de formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  institucion: null,

}

const getters = {
  fields_oferta_academica (state) {
    return state.fields_oferta_academica
  },
}
const actions = {
    
  
}

const mutations = {

}

export default {
  state,
  actions,
  mutations,
  getters
}
