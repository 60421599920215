import ApiService from '@/common/api.service'
import {
  CREATE_COMPETENCIA,
  READ_COMPETENCIA,
  UPDATE_COMPETENCIA,
  LIST_COMPETENCIAS,
  LIST_COMPETENCIAS_NIVELES,
  LIST_COMPETENCIAS_GRADOS,
  CREATE_COMPETENCIA_WIZARD
} from './actions.type'
import {
  SET_COMPETENCIA,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_competencias: {
    btn_guardar: 'false',
    list_evaluaciones: [],
    isEditing: false,
    group_datos_basicos: {    
      json_competencia_indicadores: {
        json_competencia_indicadores: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_competencia_indicadores',
          label: 'Datos basicos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'Institución  *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'Institución  *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_nivel_educativo: {
        nu_id_nivel_educativo: '',
        caracteristicas: {
          nombre: 'nu_id_nivel_educativo',
          label: 'nu_id_indicador_logro *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_grado: {
        nu_id_grado: '',
        caracteristicas: {
          nombre: 'nu_id_grado',
          label: 'Código *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código del indicador de logro',
          tooltip: 'Código del indicador de logro',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      nu_id_area_formacion: {
        nu_id_area_formacion: '',
        caracteristicas: {
          nombre: 'nu_id_area_formacion',
          label: 'Código *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código del indicador de logro',
          tooltip: 'Código del indicador de logro',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_competencia: {
        tx_competencia: '',
        caracteristicas: {
          nombre: 'tx_competencia',
          label: 'Competencia *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la competencia',
          tooltip: 'Competencia',
          required: 'required',        
          default: ''
        }
      },
      co_indicador: {
        co_indicador: '',
        caracteristicas: {
          nombre: 'co_indicador',
          label: 'Código del indicador *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introoduzca  el Código del indicador',
          tooltip: 'Código del indicador',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_indicador: {
        tx_nombre_indicador: '',
        caracteristicas: {
          nombre: 'tx_nombre_indicador',
          label: 'Nombre del indicador *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del indicador',
          tooltip: 'Nombre del indicador',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_descripcion_indicador: {
        tx_descripcion_indicador: '',
        caracteristicas: {
          nombre: 'tx_descripcion_indicador',
          label: 'Descripción del indicador *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la descripción del indicador',
          tooltip: 'Descripción del indicador',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  evaluacion: null,

}

const getters = {
  
  fields_competencias (state) {
    return state.fields_competencias
  },
  evaluacion (state) {
    return state.evaluacion
  },
  list_evaluaciones (state) {
    return state.list_evaluaciones
  }

}
const actions = {
  [CREATE_COMPETENCIA_WIZARD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCompetenciaWizard/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_COMPETENCIAS] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_competencias
    return new Promise((resolve, reject) => {
      ApiService.get('WSCompetencia', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_COMPETENCIAS_GRADOS] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_competencias
    return new Promise((resolve, reject) => {
      ApiService.get('WSCompetenciaGrados', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_COMPETENCIAS_NIVELES] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_competencias
    return new Promise((resolve, reject) => {
      ApiService.get('WSCompetenciaNiveles', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_COMPETENCIA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSCompetencia', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_COMPETENCIA] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCompetencia/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_COMPETENCIA] (context, datos) {
    console.log('Registrar UPDATE_COMPETENCIA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSCompetencia/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_COMPETENCIA] (state, evaluacion) {
    state.evaluacion = evaluacion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
