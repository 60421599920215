import ApiService from '@/common/api.service'
import {
  CREATE_REPRESENTANTE,
  READ_REPRESENTANTE,
  UPDATE_REPRESENTANTE,
  LIST_REPRESENTANTE,
} from './actions.type'
import {
  SET_REPRESENTANTE,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_representante: {
    group_datos_basicos: {    
      nu_id_representante: {
        nu_id_representante: '',
        caracteristicas: {
          nombre: 'nu_id_representante',
          label: 'Persona *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_historia: {
        nu_id_historia: '',
        caracteristicas: {
          nombre: 'nu_id_historia',
          label: 'Tipo persona *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el tipo de persona',
          tooltip: 'Tipo persona',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      nu_id_persona: {
        nu_id_persona: '',
        caracteristicas: {
          nombre: 'nu_id_persona',
          label: 'Tipo persona *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el tipo de persona',
          tooltip: 'Tipo persona',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      nu_id_parentesco: {
        nu_id_parentesco: '',
        caracteristicas: {
          nombre: 'nu_id_parentesco',
          label: 'Parentesco con el paciente *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el parentesco',
          tooltip: 'Parentesco',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_nacionalidad: {
        tx_nacionalidad: '',
        caracteristicas: {
          nombre: 'tx_nacionalidad',
          label: 'Nacionalidad *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese nacionalidad',
          tooltip: 'Nacionalidad del paciente',
          required: 'required',        
          default: ''
        }
      },
      tx_documento_identidad: {
        tx_documento_identidad: '',
        caracteristicas: {
          nombre: 'tx_documento_identidad',
          label: 'Cédula *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Ingrese cédula de identidad',
          tooltip: 'Cédula de identidad del paciente',
          tooltip_pos:'bottom',
          required: 'required|max:10|numeric',        
          default: ''
        }
      },
      tx_apellidos: {
        tx_apellidos: '',
        caracteristicas: {
          nombre: 'tx_apellidos',
          label: 'Apellidos *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese los apellidos del paciente',
          tooltip: 'Apellidos del paciente',
          required: 'required',        
          default: ''
        }
      },
      tx_nombres: {
        tx_nombres: '',
        caracteristicas: {
          nombre: 'tx_nombres',
          label: 'Nombres *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese los nombres del paciente',
          tooltip: 'Nombres del paciente',
          required: 'required',        
          default: ''
        }
      },
      nu_sexo: {
        nu_sexo: '',
        caracteristicas: {
          nombre: 'nu_sexo',
          label: 'Sexo *',
          tipo: 'date',
          tamano: 'text-sm',
          placeholder: 'Seleccione el sexo del paciente',
          tooltip: 'Sexo del paciente',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_direccion: {
        tx_direccion: '',
        caracteristicas: {
          nombre: 'tx_direccion',
          label: 'Dirección *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Ingrese la dirección del paciente',
          tooltip: 'Dirección del paciente',
          tooltip_pos:'bottom',
          required: 'required',   
          default: ''
        }
      },
      tx_telefono_local: {
        tx_telefono_local: '',
        caracteristicas: {
          nombre: 'tx_telefono_local',
          label: 'Teléfono local',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese el teléfonico local',
          tooltip: 'Número teléfonico local',
          tooltip_pos:'bottom',
          default: ''
        }
      },
      tx_telefono_celular: {
        tx_telefono_celular: '',
        caracteristicas: {
          nombre: 'tx_telefono_celular',
          label: 'Teléfono celular',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese el teléfonico celular',
          tooltip: 'Número teléfonico celular',
          tooltip_pos:'bottom',
          default: ''
        }
      },
      tx_correo: {
        tx_correo: '',
        caracteristicas: {
          nombre: 'tx_correo',
          label: 'Correo electrónico',
          tipo: 'boolean',
          tamano: 'text-sm',
          placeholder: 'Ingrese el correo electrónico',
          tooltip: 'Correo electrónico',
          tooltip_pos:'bottom',
          default: ''
        }
      },


    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },
    btn_guardar: 'false',
    select_representante: '',
    isEditing: false,
  },
  representante: null,

}

const getters = {
  
  fields_representante (state) {
    return state.fields_representante
  },
  representante (state) {
    return state.representante
  }

}
const actions = {
  [LIST_REPRESENTANTE] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_representante
    return new Promise((resolve, reject) => {
      ApiService.get('WSPacienteRepresentante', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_REPRESENTANTE] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSRepresentanteI', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_REPRESENTANTE] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSPacienteRepresentante/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_REPRESENTANTE] (context, datos) {
    console.log('Registrar UPDATE_REPRESENTANTE: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSRepresentanteI/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_REPRESENTANTE] (state, representante) {
    state.representante = representante
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
