import ApiService from '@/common/api.service'


import {
  REG_COMENTARIO
} from '@/store/actions.type'

import {
  SET_ERROR,
  SET_COMENTARIO  
} from '@/store/mutations.type'

const state = {
  comentarios: [],
}


const getters = {
  comentarios (state) {
    return state.comentarios
  }

}
const actions = {
  [REG_COMENTARIO] (context, datos) {
    console.log('Registrar REG_COMENTARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSComentario/registrar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_COMENTARIO] (state, valor) {
    state.comentarios = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
