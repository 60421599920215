import ApiService from '@/common/api.service'
import {
  READ_RADIOLOGIA,
  CREATE_RADIOLOGIA,
  UPDATE_RADIOLOGIA
} from './actions.type'
import {
  SET_RADIOLOGIA,
  SET_ERROR
} from './mutations.type'

const state = {
  radiologia: null
}

const getters = {
  radiologia (state) {
    return state.radiologia
  }

}
const actions = {
  [READ_RADIOLOGIA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSRadiologia', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_RADIOLOGIA] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSRadiologia/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_RADIOLOGIA] (context, datos) {
    console.log('Registrar UPDATE_RADIOLOGIA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSRadiologia/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_RADIOLOGIA] (state, radiologia) {
    state.radiologia = radiologia
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
