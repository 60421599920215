import ApiService from '@/common/api.service'
import {
  REG_SEGURO,
  MUESTRA_SEGURO
} from '@/store/actions.type'

import {
  SET_ERROR,
  SET_SEGURO  
} from '@/store/mutations.type'

const state = {
  seguros: [],
}


const getters = {
  seguros (state) {
    return state.seguros
  }

}
const actions = {
  [MUESTRA_SEGURO] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSSeguro', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },  
  [REG_SEGURO] (context, datos) {
    console.log('Registrar REG_SEGURO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSSeguro/registrar',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_SEGURO] (state, valor) {
    state.seguros = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
