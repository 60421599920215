import ApiService from '@/common/api.service'
import {
  CREATE_EVALUACION,
  READ_EVALUACION,
  UPDATE_EVALUACION,
  LIST_EVALUACION,
  LIST_EVALUACION_NIVELES,
  LIST_EVALUACION_GRADOS,
  CREATE_EVALUACION_WIZARD,
  LIST_PLAN_EVALUACION_AREA,
  CREATE_PLAN_EVALUACION_WIZARD,
  LIST_PLAN_EVALUACION
} from './actions.type'
import {
  SET_EVALUACION,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_evaluacion: {
    btn_guardar: 'false',
    list_evaluaciones: [],
    isEditing: false,
    group_datos_basicos: {    
      nu_id_ano_escolar: {
        nu_id_ano_escolar: '',
        caracteristicas: {
          nombre: 'nu_id_lapso',
          label: 'nu_id_ano_escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'nu_id_institucion *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_nivel_educativo: {
        nu_id_nivel_educativo: '',
        caracteristicas: {
          nombre: 'nu_id_nivel_educativo',
          label: 'nu_id_ano_escolar *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_grado: {
        nu_id_grado: '',
        caracteristicas: {
          nombre: 'nu_id_grado',
          label: 'nu_id_grado *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_area_formacion: {
        nu_id_area_formacion: '',
        caracteristicas: {
          nombre: 'nu_id_area_formacion',
          label: 'nu_id_grado *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'nu_id_ano_escolar',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_lapso: {
        nu_id_lapso: '',
        caracteristicas: {
          nombre: 'nu_id_lapso',
          label: 'Momento/Lapso/Periodo *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione el Momento/Lapso/Periodo',
          tooltip: 'Momento/Lapso/Periodo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_plan_evaluacion: {
        co_plan_evaluacion: '',
        caracteristicas: {
          nombre: 'co_plan_evaluacion',
          label: 'Código plan evaluación *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código del plan de evaluación',
          tooltip: 'Código del plan de evaluación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_denominacion_plan_evaluacion: {
        tx_denominacion_plan_evaluacion: '',
        caracteristicas: {
          nombre: 'tx_denominacion_plan_evaluacion',
          label: 'Denominación del plan de evaluación *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la denominación del plan de evaluación',
          tooltip: 'Denominación del plan de evaluación',
          required: 'required',        
          default: ''
        }
      },
      tx_descripcion_plan_evaluacion: {
        tx_descripcion_plan_evaluacion: '',
        caracteristicas: {
          nombre: 'tx_descripcion_plan_evaluacion',
          label: 'Descripción del plan de evaluación *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca descripción del plan de evaluación',
          tooltip: 'Descripción del plan de evaluación',
          required: 'required',        
          default: ''
        }
      },
    },
    group_criterio_evaluacion: {    
      json_group_criterio_evaluacion: {
        json_group_criterio_evaluacion: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_group_criterio_evaluacion',
          label: 'Datos basicos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_criterio_evaluacion: {
        nu_id_criterio_evaluacion: '',
        caracteristicas: {
          nombre: 'nu_id_criterio_evaluacion',
          label: 'Código del criterio de evaluación *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca código del criterio de evaluación',
          tooltip: 'Código del criterio de evaluación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_criterio_evaluacion: {
        co_criterio_evaluacion: '',
        caracteristicas: {
          nombre: 'co_criterio_evaluacion',
          label: 'Código del criterio de evaluación *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca código del criterio de evaluación',
          tooltip: 'Código del criterio de evaluación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_denominacion_criterio_evaluacion: {
        tx_denominacion_criterio_evaluacion: '',
        caracteristicas: {
          nombre: 'tx_denominacion_criterio_evaluacion',
          label: 'Nombre del criterio *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del criterio de evaluación',
          tooltip: 'Nombre del criterio de evaluación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      nu_ponderacion_criterio: {
        nu_ponderacion_criterio: '',
        caracteristicas: {
          nombre: 'nu_ponderacion_criterio',
          label: 'Ponderación del criterio *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la ponderación del criterio',
          tooltip: 'Ponderación del criterio',
          required: 'required',        
          default: ''
        }
      },
    },
    group_indicador_aprendizaje: {    
      json_group_indicador_aprendizaje: {
        json_group_indicador_aprendizaje: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_group_indicador_aprendizaje',
          label: 'Datos basicos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_criterio_indicador_aprendizaje: {
        json_criterio_indicador_aprendizaje: '',
        caracteristicas: {
          nombre: 'json_criterio_indicador_aprendizaje',
          label: 'Criterio de evaluación *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca criterio de evaluación',
          tooltip: 'Criterio de evaluación',
          required: 'required',        
          default: ''
        }
      },
      json_total_criterio_indicador:{
        json_total_criterio_indicador: [],
        caracteristicas: {
          nombre: 'json_total_criterio_indicador',
          label: 'json_total_criterio_indicador *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la competencia',
          tooltip: 'Competencias',
          required: 'required',        
          default: ''
        }
      },
      json_competencia: {
        json_competencia: '',
        caracteristicas: {
          nombre: 'json_competencia',
          label: 'Competencias *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la competencia',
          tooltip: 'Competencias',
          required: 'required',        
          default: ''
        }
      },
      json_indicador: {
        json_indicador: '',
        caracteristicas: {
          nombre: 'json_indicador',
          label: 'Indicador *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el indicador',
          tooltip: 'Indicador',
          required: 'required',        
          default: ''
        }
      },
      co_indicador_aprendizaje: {
        co_indicador_aprendizaje: '',
        caracteristicas: {
          nombre: 'co_indicador_aprendizaje',
          label: 'Código del indicador de aprendizaje *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el indicador de aprendizaje',
          tooltip: 'Código del indicador de aprendizaje',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_nombre_indicador_aprendizaje: {
        tx_nombre_indicador_aprendizaje: '',
        caracteristicas: {
          nombre: 'tx_nombre_indicador_aprendizaje',
          label: 'Nombre del indicador de aprendizaje *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el nombre del indicador de aprendizaje',
          tooltip: 'Nombre del indicador de aprendizaje',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      nu_ponderacion_indicador_aprendizaje: {
        nu_ponderacion_indicador_aprendizaje: '',
        caracteristicas: {
          nombre: 'nu_ponderacion_indicador_aprendizaje',
          label: 'Ponderación del indicador de aprendizaje *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la ponderación del indicador de aprendizaje',
          tooltip: 'Ponderación del indicador de aprendizaje',
          required: 'required',        
          default: ''
        }
      },
    },
    group_indicador_logro: {    
      json_group_indicador_logro: {
        json_group_indicador_logro: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_group_indicador_logro',
          label: 'Datos basicos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      json_criterio_indicador_logro: {
        json_criterio_indicador_logro: '',
        caracteristicas: {
          nombre: 'json_criterio_indicador_logro',
          label: 'Criterio de evaluación *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Introduzca criterio de evaluación',
          tooltip: 'Criterio de evaluación',
          required: 'required',        
          default: ''
        }
      },
      json_competencia: {
        json_competencia: '',
        caracteristicas: {
          nombre: 'json_competencia',
          label: 'Competencias *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la competencia',
          tooltip: 'Competencias',
          required: 'required',        
          default: ''
        }
      },
      json_indicador: {
        json_indicador: '',
        caracteristicas: {
          nombre: 'json_indicador',
          label: 'Indicador de aprendizaje *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el indicador',
          tooltip: 'Indicador',
          required: 'required',        
          default: ''
        }
      },
      json_indicador_logro: {
        json_indicador_logro: '',
        caracteristicas: {
          nombre: 'json_indicador_logro',
          label: 'Indicador de logro *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el indicador',
          tooltip: 'Indicador',
          required: 'required',        
          default: ''
        }
      },
      nu_ponderacion_indicador_logro: {
        nu_ponderacion_indicador_logro: '',
        caracteristicas: {
          nombre: 'nu_ponderacion_indicador_logro',
          label: 'Ponderación del indicador *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca la ponderación del indicado',
          tooltip: 'Ponderación del indicador',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  evaluacion: null,

}

const getters = {
  
  fields_evaluacion (state) {
    return state.fields_evaluacion
  },
  evaluacion (state) {
    return state.evaluacion
  },
  list_evaluaciones (state) {
    return state.list_evaluaciones
  }

}
const actions = {
  [CREATE_EVALUACION_WIZARD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSInstitucionWizard/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [CREATE_PLAN_EVALUACION_WIZARD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSPlanEvaluacionWizard/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })

  },
  [LIST_EVALUACION] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_evaluacion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_PLAN_EVALUACION] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_evaluacion
    return new Promise((resolve, reject) => {
      ApiService.get('WSPlanEvaluacion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_PLAN_EVALUACION_AREA] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_evaluacion
    return new Promise((resolve, reject) => {
      ApiService.get('WSPlanEvaluacionArea', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_EVALUACION_GRADOS] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_evaluacion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucionGrados', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_EVALUACION_NIVELES] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_evaluacion
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucionNiveles', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_EVALUACION] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSInstitucion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_EVALUACION] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSInstitucion/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_EVALUACION] (context, datos) {
    console.log('Registrar UPDATE_EVALUACION: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSInstitucion/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_EVALUACION] (state, evaluacion) {
    state.evaluacion = evaluacion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
