import ApiService from '@/common/api.service'
import {
    READ_AGENDA_VISITAS,
    READ_AGENDA_CONSULTAS    
} from './actions.type'


const state = {
  agenda_visita_medica: [],
}

const getters = {
    agenda_visita_medica (state) {
    return state.agenda_visita_medica
  },  

}
const actions = {
  [READ_AGENDA_VISITAS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSAgendaVisitaMedica', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_AGENDA_CONSULTAS] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSAgendaConsultaMedica', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  
}

const mutations = {

}

export default {
  state,
  actions,
  mutations,
  getters
}
