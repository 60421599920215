import ApiService from '@/common/api.service'
import {
  READ_VISITA_MEDICA,
  CREATE_VISITA_MEDICA,
  UPDATE_VISITA_MEDICA
} from './actions.type'
import {
  SET_VISITA_MEDICA,
  SET_ERROR
} from './mutations.type'

const state = {
  especialidad: null,
  fields_visita_medica: {
    nu_id_visita_medica: {
      nu_id_visita_medica: '',
      caracteristicas: {
        nombre: 'nu_id_visita_medica',
        label: 'Fecha de visita *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Fecha de visita',
        tooltip: 'Fecha de visita',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    nu_id_paciente: {
      nu_id_paciente: '',
      caracteristicas: {
        nombre: 'nu_id_paciente',
        label: 'Fecha de visita *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Fecha de visita',
        tooltip: 'Fecha de visita',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    json_centro_medico: {
      json_centro_medico: '',
      caracteristicas: {
        nombre: 'json_centro_medico',
        label: 'Lugar de la visita *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione  el lugar de la visita',
        tooltip: 'Lugar de la visita',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    json_especialidad: {
      json_especialidad: '',
      caracteristicas: {
        nombre: 'json_especialidad',
        label: 'Especialidad *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione la especialidad',
        tooltip: 'Especialidad',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    json_paciente: {
      json_paciente: '',
      caracteristicas: {
        nombre: 'json_paciente',
        label: 'Especialidad *',
        tipo: 'JSON',
        tamano: 'text-sm',
        placeholder: 'Seleccione la especialidad',
        tooltip: 'Especialidad',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    group: '',
    fe_visita_medica: {
      fe_visita_medica: '',
      caracteristicas: {
        nombre: 'fe_visita_medica',
        label: 'Fecha de visita *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Seleccione la Fecha de visita',
        tooltip: 'Fecha de visita',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    hh_visita_medica: {
      hh_visita_medica: '',
      caracteristicas: {
        nombre: 'hh_visita_medica',
        label: 'Hora de visita *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese la hora de visita',
        tooltip: 'Hora de visita',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    tx_direccion: {
      tx_direccion: '',
      caracteristicas: {
        nombre: 'tx_direccion',
        label: 'Dirección *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese la dirección',
        tooltip: 'Dirección',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    tx_habitacion: {
      tx_habitacion: '',
      caracteristicas: {
        nombre: 'tx_habitacion',
        label: 'Habitación *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el número de habitación',
        tooltip: 'Nro de Habitación',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    tx_motivo: {
      tx_motivo: '',
      caracteristicas: {
        nombre: 'tx_motivo',
        label: 'Motivo *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el motivo de la visita',
        tooltip: 'Motivo de la visita',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    tx_piso: {
      tx_piso: '',
      caracteristicas: {
        nombre: 'tx_piso',
        label: 'Piso *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el piso donde esta ubicada la habitación',
        tooltip: 'Ingrese el piso donde esta ubicada la habitación',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    nu_tiempo_estimado: {
      nu_tiempo_estimado: '',
      caracteristicas: {
        nombre: 'nu_tiempo_estimado',
        label: 'Tiempo estimado de la consulta *',
        tipo: 'number',
        tamano: 'text-sm',
        placeholder: 'Seleccione el Tiempo estimado de la consulta',
        tooltip: 'Tiempo estimado de la consulta',
        tooltip_pos:'bottom',
        required: 'required',        
        default: ''
      }
    },
    co_status_visita_medica: {
      co_status_visita_medica: 'AGE_PRO',
      caracteristicas: {
        nombre: 'co_status_visita_medica',
        label: 'Piso *',
        tipo: 'text',
        tamano: 'text-sm',
        placeholder: 'Ingrese el piso donde esta ubicada la habitación',
        tooltip: 'Ingrese el piso donde esta ubicada la habitación',
        tooltip_pos:'bottom',
        required: 'required',        
        default: 'AGE_PRO'
      }
    },
    btn_guardar: 'false',
    isEditing: false    
  }
}

const getters = {
  fields_visita_medica (state) {
    return state.fields_visita_medica
  },    
  especialidad (state) {
    return state.especialidad
  }

}
const actions = {
  [READ_VISITA_MEDICA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSVisitaMedica', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_VISITA_MEDICA] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSVisitaMedica/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_VISITA_MEDICA] (context, datos) {
    console.log('Registrar UPDATE_VISITA_MEDICA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSVisitaMedica/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_VISITA_MEDICA] (state, especialidad) {
    state.especialidad = especialidad
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
