import ApiService from '@/common/api.service'
import {
  MUESTRA_MENU
} from './actions.type'
import {
  SET_MENU,
  SET_ACTIVO_MENU,
  SET_OPEN_TAB
} from './mutations.type'

const state = {
  menues: {},
  activo_menu: [],
  errores: null,
  openTab: 1
}

const getters = {
  menues (state) {
    return state.menues
  },
  activo_menu (state) {
    return state.activo_menu
  },
  openTab (state) {
    return state.openTab
  }  
}
const actions = {
  [MUESTRA_MENU] (context, datos) {
    //ApiService.setHeader()
    ApiService.init()

    return   new Promise((resolve, reject) => {
      ApiService.get('WSMenu', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },  
}

const mutations = {
  [SET_MENU] (state, menues) {
    state.menues = menues
  },
  [SET_ACTIVO_MENU] (state, activo) {
    console.log('Arreglo comparacion 2: ', activo)
    state.activo_menu = activo
  },
  [SET_OPEN_TAB] (state, id) {
    state.openTab = id
  }    
}

export default {
  state,
  actions,
  mutations,
  getters
}
