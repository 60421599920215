<template>
  <v-app>
    <vertical-nav-menu 
    :is-drawer-open.sync="isDrawerOpen"
    v-if="currentUser.username"
    ></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center md:mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none md:me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <div v-if="currentUser.username" class="mr-10">
            {{ currentUser.login_institucion.tx_razon_social}}            
          </div>
          <!--<v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>
-->

          <v-spacer></v-spacer>
        
          <Notificacion />
          <!-- Right Content -->
          <div v-if="currentUser.username" class="mr-10">
            {{ currentUser.perfiles[0].tx_desc_perfil}}

            
          </div>
          <router-link
            to="/pages/login"
            class="d-flex md:align-center text-decoration-none"
            v-if="!currentUser.username"
          >
              <v-btn
                small
                class="ms-4 me-2 md:ms-6 md:me-4"
              >
                  <span>Iniciar Sesión</span>
              </v-btn>
          </router-link>
          <router-link
            to="/pages/register"
            class="d-flex align-center text-decoration-none"
            v-if="!currentUser.username"
          >
              <v-btn
                small
                class="ms-6 me-4"
              >
                  <span>Registrar usuario</span>
              </v-btn>
          </router-link>          
          <theme-switcher></theme-switcher>
          <v-btn
            icon
            small
            class="ms-3"
            v-if="currentUser.username"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu 
            v-if="currentUser.username"
          ></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container md:pa-6 border-t-2 border-b-2 border-gray-100">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2022 <a
              href="https://themeselection.com"
              class="text-decoration-none"
              target="_blank"
            >NorwServices</a></span>
          <!--<span class="d-sm-inline d-none">
            <a
              href="https://themeselection.com/products/category/download-free-admin-templates/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Correo</a>
            <a
              href="https://themeselection.com/blog/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Blog</a>
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
            >Licencia</a>
          </span>
          -->
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
 import { reactive  } from "@vue/composition-api"
 import { useVuex } from '@vueblocks/vue-use-vuex'
import { MUESTRA_MENU } from '@/store/actions.type'
import { SET_ERROR, SET_MENU, SET_USER } from '@/store/mutations.type' 
import Notificacion from "@/components/Utilitarios/Notificacion";

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    Notificacion
  },
  setup() {
    const icons = reactive({
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      });    
    const isDrawerOpen = ref(null)
    const { useGetters } = useVuex()    

    return {
      ...useGetters([
        'currentUser',
        // ...
      ]),        
      isDrawerOpen,

      // Icons
      icons
    }
  },
  data () {
    return {
      username: "",
      password: '',
    }
  },  
  methods: {
      async  mostrarMenu() {
          await this.$store.dispatch(MUESTRA_MENU,{}, {}).then(response => {
              this.$store.commit(SET_USER, {
                  token: localStorage.getItem('JWT__refresh__token'),
                  username: response.usuario,
                  perfiles: response.rest.perfiles,
                  autorizacion: response.rest.autorizacion,
                  login_institucion: response.rest.login_institucion,
                  ano_escolar_activo: response.rest.ano_escolar_activo
                }
              )
              //this.$store.commit(SET_USER, response.usuario)
              this.$store.commit(SET_MENU, response.rest.menues)
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })   
    //    if (this.currentUser.username) {

    //   } 
      },  
  },
  mounted () {
      this.mostrarMenu()
      
  },      
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
