import ApiService from '@/common/api.service'
import {
  LIST_AREAS_FORMACION,
  CREATE_AREA_FORMACION  
} from './actions.type'
import {
  SET_ASOCIACIONES,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_corporativas: {
    btn_guardar: 'false',
    list_instituciones: [],
    isEditing: false,
    group_area_formacion: {    
      json_areas_formacion: {
        json_areas_formacion: [],
        caracteristicas: {
          nombre: 'json_areas_formacion',
          label: 'Institución *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione la institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_area_formacion: {
        nu_id_area_formacion: '',
        caracteristicas: {
          nombre: 'nu_id_area_formacion',
          label: 'Id área *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Id área',
          tooltip: 'Id área',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_descripcion: {
        tx_descripcion: '',
        caracteristicas: {
          nombre: 'tx_descripcion',
          label: 'Área de formación *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el Área de formación',
          tooltip: 'Área de formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_area_formacion: {
        co_area_formacion: '',
        caracteristicas: {
          nombre: 'co_area_formacion',
          label: 'Código área de formación *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código de área formación',
          tooltip: 'Área de formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_tipo: {
        tx_tipo: '',
        caracteristicas: {
          nombre: 'tx_tipo',
          label: 'Tipo *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'Tipo ',
          tooltip: 'Código área formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_status: {
        co_status: '',
        caracteristicas: {
          nombre: 'co_status',
          label: 'Status *',
          tipo: 'TEXT',
          tamano: 'text-sm',
          placeholder: 'co_status ',
          tooltip: 'Estatus del área formación',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  institucion: null,

}

const getters = {
  fields_corporativas (state) {
    return state.fields_corporativas
  },
}
const actions = {
  [CREATE_AREA_FORMACION] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSAreaFormacion/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_AREAS_FORMACION] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_corporativas
    return new Promise((resolve, reject) => {
      ApiService.get('WSAreaFormacion', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_ASOCIACIONES] (state, institucion) {
    state.institucion = institucion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
