import ApiService from '@/common/api.service'
import {
  CREATE_INDICADOR_LOGRO,
  READ_INDICADOR,
  UPDATE_INDICADOR,
  LIST_INDICADORES_LOGRO,
  LIST_INDICADORES_LOGRO_NIVELES,
  LIST_INDICADORES_LOGRO_GRADOS,
  CREATE_INDICADOR_LOGRO_WIZARD
} from './actions.type'
import {
  SET_INDICADOR,
  SET_ERROR
} from './mutations.type'

const state = {
  fields_indicadores: {
    btn_guardar: 'false',
    list_evaluaciones: [],
    isEditing: false,
    group_datos_basicos: {    
      json_indicadores_logro: {
        json_indicadores_logro: [],
        insert: 'NO',
        caracteristicas: {
          nombre: 'json_indicadores_logro',
          label: 'Datos basicos *',
          tipo: 'JSON',
          tamano: 'text-sm',
          placeholder: 'Seleccione el nivel',
          tooltip: 'Nivel educativo',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_institucion: {
        nu_id_institucion: '',
        caracteristicas: {
          nombre: 'nu_id_institucion',
          label: 'Institución  *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Institución',
          tooltip: 'Institución',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      nu_id_indicador_logro: {
        nu_id_indicador_logro: '',
        caracteristicas: {
          nombre: 'nu_id_indicador_logro',
          label: 'nu_id_indicador_logro *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Seleccione la Parroquia',
          tooltip: 'Parroquia',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      co_indicador_logro: {
        co_indicador_logro: '',
        caracteristicas: {
          nombre: 'co_indicador_logro',
          label: 'Código *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código del indicador de logro',
          tooltip: 'Código del indicador de logro',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_nombre_indicador_logro: {
        tx_nombre_indicador_logro: '',
        caracteristicas: {
          nombre: 'tx_nombre_indicador_logro',
          label: 'Indicador de logro *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese Introduzca Indicador de logro',
          tooltip: 'Indicador de logro',
          required: 'required',        
          default: ''
        }
      },
      tx_descripcion_indicador_logro: {
        tx_descripcion_indicador_logro: '',
        caracteristicas: {
          nombre: 'tx_descripcion_indicador_logro',
          label: 'Descripción del indicador de logro *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Ingrese Descripción del indicador de logro',
          tooltip: 'Descripción del indicador de logro',
          required: 'required',        
          default: ''
        }
      },
      co_literal: {
        co_literal: '',
        caracteristicas: {
          nombre: 'co_literal',
          label: 'Código *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca el código del literal',
          tooltip: 'Código del literal',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }

      },
      tx_nombre_literal: {
        tx_nombre_literal: '',
        caracteristicas: {
          nombre: 'tx_nombre_literal',
          label: 'Nombre del literal *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca Nombre del literal',
          tooltip: 'Nombre del literal',
          required: 'required',        
          default: ''
        }
      },
      nu_valor_minimo: {
        nu_valor_minimo: '',
        caracteristicas: {
          nombre: 'nu_valor_minimo',
          label: 'Valor mínimo *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca valor mínimo',
          required: 'required',        
          tooltip: 'Valor mínimo',
          default: ''
        }
      },
      nu_valor_maximo: {
        nu_valor_maximo: '',
        caracteristicas: {
          nombre: 'nu_valor_maximo',
          label: 'Valor máximo *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Introduzca valor máximo',
          tooltip: 'Valor máximo',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },

  },
  evaluacion: null,

}

const getters = {
  
  fields_indicadores (state) {
    return state.fields_indicadores
  },
  evaluacion (state) {
    return state.evaluacion
  },
  list_evaluaciones (state) {
    return state.list_evaluaciones
  }

}
const actions = {
  [CREATE_INDICADOR_LOGRO_WIZARD] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSIndicadorLogroWizard/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [LIST_INDICADORES_LOGRO] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_indicadores
    return new Promise((resolve, reject) => {
      ApiService.get('WSIndicadorLogro', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_INDICADORES_LOGRO_GRADOS] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_indicadores
    return new Promise((resolve, reject) => {
      ApiService.get('WSIndicadorLogroGrados', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [LIST_INDICADORES_LOGRO_NIVELES] (context, datos) {
    // ApiService.setHeader()
    //datos.fields = state.fields_indicadores
    return new Promise((resolve, reject) => {
      ApiService.get('WSIndicadorLogroNiveles', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [READ_INDICADOR] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WSIndicadorLogro', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_INDICADOR_LOGRO] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSIndicadorLogro/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_INDICADOR] (context, datos) {
    console.log('Registrar UPDATE_INDICADOR: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WSIndicadorLogro/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
    
  
}

const mutations = {
  [SET_INDICADOR] (state, evaluacion) {
    state.evaluacion = evaluacion
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
