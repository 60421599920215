import ApiServiceImagenes from '@/common/api.imagenes.service'


import {
  UPLOAD,
  DOWNLOAD
} from '@/store/actions.type'

import {
  SET_ERROR,
  SET_VENTA  
} from '@/store/mutations.type'

const state = {
  ventas: [],
}


const getters = {
  ventas (state) {
    return state.ventas
  }

}
const actions = {
  [UPLOAD] (context, datos,opciones) {
    // ApiServiceImagenes.setHeader()
    ApiServiceImagenes.init()
    return new Promise((resolve, reject) => {
      ApiServiceImagenes.post('/WSFiles/carga',datos,opciones)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },  
  [DOWNLOAD] (context, datos) {
    console.log('Registrar REG_VENTA: ', datos)
    // ApiServiceImagenes.setHeader()
    ApiServiceImagenes.init()
    return new Promise((resolve, reject) => {
      ApiServiceImagenes.get('/WSFiles/descarga', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
}

/* eslint no-param-reassign: ['error', { 'props': false }] */
const mutations = {
  [SET_VENTA] (state, valor) {
    state.ventas = valor
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
