import ApiService from '@/common/api.service'
import {
  READ_HISTORIA_CLINICA,
  CREATE_HISTORIA_CLINICA,
  UPDATE_HISTORIA_CLINICA,
} from './actions.type'
import {
  SET_HISTORIA_CLINICA,
  SET_ERROR
} from './mutations.type'

const state = {
  historia_clinica: null,
  fields_historia_clinica: {
    persona: {},
    paciente: {},
    group_historia_clinica: {
      nu_id_consulta: {
          nu_id_consulta: '',
          caracteristicas: {
            nombre: 'nu_id_consulta',
            label: 'Centro médico *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Ingrese el Centro médico',
            tooltip: 'Centro médico',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        nu_id_historia: {
          nu_id_historia: '',
          caracteristicas: {
            nombre: 'nu_id_historia',
            label: 'Centro médico *',
            tipo: 'number',
            tamano: 'text-sm',
            placeholder: 'Ingrese el Centro médico',
            tooltip: 'Centro médico',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        tx_historia: {
          tx_historia: '',
          caracteristicas: {
            nombre: 'tx_historia',
            label: 'Nro de historia clínica *',
            tipo: 'text',
            tamano: 'text-sm',
            placeholder: 'Intruduzca el nro de historia clínica',
            tooltip: 'Historia clínica del paciente',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          },
                    
        },
        fe_apertura: {
          fe_apertura: '',
          caracteristicas: {
            nombre: 'fe_apertura',
            label: 'Fecha de apertura *',
            tipo: 'date',
            tamano: 'text-sm',
            placeholder: 'Intruduzca la fecha de apertura',
            tooltip: 'Fecha de apertura',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        hh_apertura: {
          hh_apertura: '',
          caracteristicas: {
            nombre: 'hh_apertura',
            label: 'Hora de apertura *',
            tipo: 'time',
            tamano: 'text-sm',
            placeholder: 'Intruduzca la hora de apertura',
            tooltip: 'Hora de apertura',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        tx_medico: {
          tx_medico: '',
          caracteristicas: {
            nombre: 'tx_medico',
            label: 'Médico que aperturó la historia *',
            tipo: 'time',
            tamano: 'text-sm',
            placeholder: 'Intruduzca médico que aperturó la historia',
            tooltip: 'Médico que aperturó la historia',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },

    },
    group_consulta_medica: {
      tx_motivo: {
        tx_motivo: '',
        caracteristicas: {
          nombre: 'tx_motivo',
          label: 'Motivo de la consulta *',
          tipo: 'number',
          tamano: 'text-sm',
          placeholder: 'Ingrese el motivo de la consulta',
          tooltip: 'Centro motivo de la consulta',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },    
    },
    group_enfermedad_actual: {
      tx_enfermedad_actual: {
        tx_enfermedad_actual: '',
        caracteristicas: {
          nombre: 'tx_enfermedad_actual',
          label: 'Enfermedad actual *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca la enfermedad actual',
          tooltip: 'Enfermedad actual',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },

    },
    group_antecedentes_enfermedad_actual: {
      tx_antecedentes_enfermedad_actual: {
        tx_antecedentes_enfermedad_actual: '',
        caracteristicas: {
          nombre: 'tx_antecedentes_enfermedad_actual',
          label: 'Antecedente a enfermedad actual *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca los antecedentes de la enfermedad actual',
          tooltip: 'Antecedentes a enfermedad actual',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_antecedentes_personales: {
      tx_antecedentes_personales: {
          tx_antecedentes_personales: '',
          caracteristicas: {
            nombre: 'tx_antecedentes_personales',
            label: 'Antecedente personales *',
            tipo: 'text',
            tamano: 'text-sm',
            placeholder: 'Intruduzca los antecedentes personales',
            tooltip: 'Antecedentes personales',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },
        tx_antecedentes_personales_habitos_toxicos: {
          tx_antecedentes_personales_habitos_toxicos: '',
          caracteristicas: {
            nombre: 'tx_antecedentes_personales_habitos_toxicos',
            label: 'Hábitos tóxicos *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Intruduzca los antecedentes de la enfermedad actual',
            tooltip: 'Antecedentes a enfermedad actual',
            tooltip_pos:'bottom',
            required: 'required',        
            default: '',
            seleccionado: ''
          }
        },
        tx_antecedentes_personales_habitos_fisiologicos: {
          tx_antecedentes_personales_habitos_fisiologicos: '',
          caracteristicas: {
            nombre: 'tx_antecedentes_personales_habitos_fisiologicos',
            label: 'Hábitos fisiológicos *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Intruduzca los antecedentes de la enfermedad actual',
            tooltip: 'Antecedentes a enfermedad actual',
            tooltip_pos:'bottom',
            required: 'required',        
            default: '',
            seleccionado: ''
          }
        },
    },
    group_antecedentes_familiares: {
      tx_antecedentes_familiares: {
        tx_antecedentes_familiares: '',
        caracteristicas: {
          nombre: 'tx_antecedentes_familiares',
          label: 'Antecedentes familiares *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca los antecedentes familiares',
          tooltip: 'Antecedentes familiares',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_examen_fisico: {
      tx_tension_arterial: {
        tx_tension_arterial: '',
        caracteristicas: {
          nombre: 'tx_tension_arterial',
          label: 'Tensión arterial *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca la tensión arterial',
          tooltip: 'Tensión arterial',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_frecuencia_cardiaca: {
        tx_frecuencia_cardiaca: '',
        caracteristicas: {
          nombre: 'tx_frecuencia_cardiaca',
          label: 'Frecuencia cardíaca *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca la Frecuencia cardíaca ',
          tooltip: 'Tensión Frecuencia cardíaca ',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_frecuencia_respiratoria: {
        tx_frecuencia_respiratoria: '',
        caracteristicas: {
          nombre: 'tx_frecuencia_respiratoria',
          label: 'Frecuencia respiratoria *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca la frecuencia respiratoria ',
          tooltip: 'Tensión frecuencia respiratoria',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_temperatura: {
        tx_temperatura: '',
        caracteristicas: {
          nombre: 'tx_temperatura',
          label: 'Temperatura *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca la temperatura ',
          tooltip: 'Temperatura',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
      tx_indice_masa_corporal: {
        tx_indice_masa_corporal: '',
        caracteristicas: {
          nombre: 'tx_indice_masa_corporal',
          label: 'Índice de masa corporal *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca índice de masa corporal ',
          tooltip: 'Índice de masa corporal',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_diagnostico: {
      tx_diagnostico: {
        tx_diagnostico: '',
        caracteristicas: {
          nombre: 'tx_diagnostico',
          label: 'Diagnóstico *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca el Diagnóstico ',
          tooltip: 'Diagnóstico',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    group_tratamiento: {
      tx_tratamiento: {
        tx_tratamiento: '',
        caracteristicas: {
          nombre: 'tx_tratamiento',
          label: 'Tratamiento *',
          tipo: 'text',
          tamano: 'text-sm',
          placeholder: 'Intruduzca el tratamiento ',
          tooltip: 'Tratamiento',
          tooltip_pos:'bottom',
          required: 'required',        
          default: ''
        }
      },
    },
    metadata: {
      metadata: '',
      caracteristicas: {
        nombre: 'metadata',
        id_create: '',
        date_create: '',
        date_update: '',
        id_update:'',
        access: {
            all: '',
            fields: {
              field: '',
              read: [],
              update:[]              
            }
        },
      }
    },
  }
}

const getters = {
  historia_clinica (state) {
    return state.historia_clinica
  },
  fields_historia_clinica (state) {
    return state.fields_historia_clinica
  },  

}
const actions = {
  [READ_HISTORIA_CLINICA] (context, datos) {
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.get('WShistoria_clinica', datos, {})
        .then(({
          data
        }) => {
          resolve(data)
        })
        .catch(({
          response
        }) => {
          reject(response)
        })
    })
  },
  [CREATE_HISTORIA_CLINICA] (context, datos) {
    console.log('Registrar REG_USUARIO: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WShistoria_clinica/create',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  },
  [UPDATE_HISTORIA_CLINICA] (context, datos) {
    console.log('Registrar UPDATE_HISTORIA_CLINICA: ', datos)
    // ApiService.setHeader()
    return new Promise((resolve, reject) => {
      ApiService.post('/WShistoria_clinica/update',datos)
        .then(({
          data
        }) => {
          // context.commit(SET_USUARIO, data.usuario)
          resolve(data)
        })
        .catch(({
          response
        }) => {
          context.commit(SET_ERROR, response.data.errors)
          reject(response)
        })
    })
  }  
  
}

const mutations = {
  [SET_HISTORIA_CLINICA] (state, historia_clinica) {
    state.historia_clinica = historia_clinica
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
