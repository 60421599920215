import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/paciente',
    name: 'paciente',
    component: () => import('@/views/paciente/Paciente.vue'),
  },  
  {
    path: '/diagnostico',
    name: 'diagnostico',
    component: () => import('@/views/paciente/Paciente.vue'),
  },  
  {
    path: '/gestionrecursos/',
    name: 'gestionrecursos',
    component: () => import('@/views/demo/Demo'),
  }, 
  {
    path: '/usuario_config_inf_general',
    name: 'usuario_config_inf_general',
    component: () => import('@/views/seguridad/UsuarioConfig')
  },     
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },    

  },
  {
    path: '/pages/login_institucion',
    name: 'pages-login-institucion',
    component: () => import('@/views/pages/LoginInstitucion.vue'),
    meta: {
      layout: 'blank',
    },    

  },
  {
    path: '/pages/logout',
    name: 'pages-logout',
    component: () => import('@/views/pages/Logout.vue'),
  },

  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    name: 'validar_reg_usuario',
    path: '/usuario/validar_reg_usuario',
    component: () => import('@/views/pages/ValidarRegUsuarioTempView'),
    meta: {
      unistraCasAuthentication: false,
      label: 'Registro'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Administrador/ListarOpcionAdminView'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/planificacion',
    name: 'planificacion',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/seguimiento',
    name: 'seguimiento',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/tareas',
    name: 'tareas',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/evaluaciones',
    name: 'evaluaciones',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/cualitativa',
    name: 'cualitativa',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/cuantitativa',
    name: 'cuantitativa',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/tratamiento',
    name: 'tratamiento',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/gestionacademica',
    name: 'gestionacademica',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/institucion',
    name: 'institucion',
    component: () => import('@/views/configuracion/Institucion/Institucion'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/areaformacion',
    name: 'areaformacion',
    component: () => import('@/views/configuracion/AreaFormacion/AreaFormacion'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/anoescolar',
    name: 'anoescolar',
    component: () => import('@/views/configuracion/AnoEscolar/AnoEscolar'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/ofertaacademica',
    name: 'ofertaacademica',
    component: () => import('@/views/academica/OfertaAcademica'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/administracioninstitucion',
    name: 'administracioninstitucion',
    component: () => import('@/views/academica/OfertaAcademica'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  

  {
    path: '/evaluacion',
    name: 'evaluacion',
    component: () => import('@/views/configuracion/Evaluacion/Evaluacion'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/planevaluacion',
    name: 'planevaluacion',
    component: () => import('@/views/configuracion/Evaluacion/PlanEvaluacionColegio/PlanEvaluacionColegio'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/calificacion',
    name: 'calificacion',
    component: () => import('@/views/demo/Demo'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  {
    path: '/seguridad',
    name: 'seguridad',
    component: () => import('@/views/configuracion/Seguridad/Seguridad'),
    meta: {
      unistraCasAuthentication: true,
      label: ''
    }
  },  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
